<h2 class="margin-bottom--3x">Skapa ny kampanj</h2>
<form class="row" autocomplete="off">
  <div class="col-md-6">
    <woo-customer-dropdown
      [items]="customers"
      [(ngModel)]="selectedCustomer"
      (ngModelChange)="customerChanged()"
      name="selectedCustomer"
      inputId="customer-search"
      labelText="Kund"
      [searchAttributes]="['name', 'nickname']"
      notFoundText="Inga kunder hittades."
      [disabled]="editingBooked || customers.length === 0"
      [labelCreate]="showCustomerNickname"
    ></woo-customer-dropdown>
  </div>

  <div class="col-md-6">
    <woo-dropdown
      *ngIf="!clientCustomer()"
      [items]="availableAgencies"
      [(ngModel)]="selectedAgency"
      (ngModelChange)="agencyChanged()"
      name="selectedAgency"
      bindLabel="nickname"
      inputId="agency-search"
      labelText="Byrå"
      [searchAttributes]="['name', 'nickname']"
      notFoundText="Inga byråer hittades."
      [clearable]="adminOrPlanner"
      [disabled]="editingBooked"
    ></woo-dropdown>
  </div>

  <div class="col-md-6">
    <woo-text-input
    labelText="Er referens"
    name="clientInvoiceReference"
    inputId="campaign-ref"
    maxlength="35"
    required="true"
    [(ngModel)]="clientInvoiceReference"
    (ngModelChange)="clientInvoiceReferenceUpdated()"
    ></woo-text-input>
  </div>
  <div class="col-md-6">
    <woo-text-input
    labelText="PO-nr/AO-nr/Internt referensnummer"
    name="referenceNumber"
    inputId="reference-nr"
    required="true"
    [(ngModel)]="referenceNumber"
    (ngModelChange)="referenceNumberUpdated()"
    ></woo-text-input>
  </div>

  <div class="col-md-6">
    <woo-text-input
    labelText="Kampanjnamn"
    name="campaignTitle"
    inputId="campaign-title"
    required="true"
    [(ngModel)]="title"
    (ngModelChange)="nameUpdated()"
    ></woo-text-input>
  </div>

  <div class="col-md-6">
    <woo-dropdown
      *ngIf="true"
      [items]="customerBrands"
      [(ngModel)]="selectedBrand"
      (ngModelChange)="brandChanged()"
      name="selectedBrand"
      bindLabel="name"
      inputId="brand-choice"
      labelText="Varumärke"
      notFoundText="Inga varumärken för denna kund"
      [clearable]="true"
    ></woo-dropdown>
  </div>

</form>

<ng-container *ngIf="selectedCustomer && adminOrPlanner">
  <h3>Adminpanel</h3>
  <div class="adminpanel--container">
    <div class="input__container">
      <input type="checkbox" name="checkbox" class="checkbox"
      [(ngModel)]="inventoryOnlyCampaign"
      (ngModelChange)="toggleInventoryOnlyCampaign()">
      <label class="checkbox">
        <ng-container>Boka som bortbokning</ng-container>
      </label>
      <info-badge>
        Om kampanjen bokas som bortbokning så filtreras den bort ifrån "kampanjer som behöver uppmärksamhet" listan. Notera att detta inte automatiskt stänger av fakturering, m.m.
      </info-badge>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="selectedCustomer">
  <h3>Rabatter</h3>

  <div class="discounts--container" *ngIf="showContractDiscounts()">
    <p class="text--large">Avtal</p>
    <new-discount-toggle [campaign]="campaign"></new-discount-toggle>
    <contract-discounts-list
      [campaign]="campaign"
      [contracts]="customerContracts"
    ></contract-discounts-list>
  </div>

  <div class="discounts--container">
    <p class="text--large">Ad Hoc-rabatter (Engång)</p>
    <p *ngIf="customerVouchers.length">
      I nästa steg anger du om du vill använda din engångsrabatt.
    </p>
    <vouchers-list
      [mode]="voucherListMode"
      [vouchers]="customerVouchers"
      [showUsedVouchers]="true"
      (onDelete)="deleteVoucher($event)"
    ></vouchers-list>
    <button
      *ngIf="!editingBooked && adminOrPlanner"
      class="link"
      (click)="createVoucher()"
    >
      Skapa ny
    </button>
  </div>

  <div class="discounts--container">
    <p class="text--large">Ad Hoc-rabatter (Period)</p>
    <p>Obegränsad användning under alla periodens dagar.</p>
    <ad-hoc-contracts-list
      [contracts]="customerAdHocContracts"
      [enableDelete]="canDeleteAdhoc"
      (onDelete)="deleteAdHoc($event)"
    ></ad-hoc-contracts-list>
    <button
      *ngIf="!editingBooked && adminOrPlanner"
      class="link"
      (click)="createAdHocContract()"
    >
      Skapa ny
    </button>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import {
  CampaignStatus,
  CombinedViewCurrencies,
  FrequencyLimit,
  FrequencyLimitTimeUnit,
  GoalUpdateBookingError,
  SlotType,
  ViewCurrency,
} from './shared-types';
import { ServerEvents } from './StandardResponseService';
import { SystemService } from './SystemService';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  discountList = {
    discount: 'Rabatt',
    conditions: 'Villkor',
    voucher: 'engångsrabatt',
    national_pause: 'Pausreklam nationell',
    national_rbs: 'Spots nationell RBS',
    national_specific: 'Spots nationell Specific', // superseded by national_specific_and_category but used on existing 2017 contracts
    national_specific_and_category: 'Spots nationell Specific',
    national_target_group: 'Spots nationell Målgrupp',
    local_pause: 'Pausreklam regional',
    local_rbs: 'Spots regional RBS',
    local_specific_and_category: 'Spots regional Specific',
  };

  ttvBookingList = {
    ttv_id: 'Bokning-Id',
    reference_number: 'Referensnummer',
    agency_crm_id: 'Byrå-Id',
    customer_crm_id: 'Kund-Id',
    order_reference: 'Orderreferens',
    customer_contact: 'Kundkontakt',
    target_group: 'TTV-målgrupp',
    additional_information: 'Extra information',
    total_net_budget: 'Budget (Netto)',
    total_gross_budget: 'Budget (Brutto)',
    discount: 'Rabatt',
    universe: 'Universum',
  };

  creativesList = {
    quota: 'Fördelning',
    budget_decimals: 'Budgetdecimal',
    destination_url: 'Destinations-URL',
    click_tracking_url: 'Tracking-URL (Klick)',
    impression_tracking_urls: 'Tracking-URLs (Impressions)',
    ad_ids: 'Ad-Ids',
    custom_name: 'Materialnamn',
    length: 'Filmlängd',
    video_code: 'Filmkod',
    asset_url: 'Video-URL',
    video_title: 'Filmtitel',
    has_image: 'Har bild',
  };

  targetingsList = {
    budget: 'Budget',
    calculate_from_budget: 'Prisberäkna från budget',
    budgeted_impressions: 'Budgeterade completed views',
    additional_budget: 'Extra/Komp budget',
    additional_budgeted_impressions: 'Extra/Komp budgeterade CV',
    additional_budget_message: 'Extrabudgets anteckning',
  };

  constructor(private systemService: SystemService) {}

  convertDiscount = (word: string): string => {
    return this.discountList[word] !== undefined ? this.discountList[word] : word;
  };

  convertHistory = (word: string): string => {
    const list = {
      // History fields
      create: 'skapade',
      update: 'uppdaterade',
      delete: 'raderade',
      destroy: 'raderade',

      // Common fields
      start_date: 'Startdatum',
      end_date: 'Slutdatum',
      external_id: 'AdServer-id',

      // Customer fields
      customer: 'kund',
      name: 'Namn',
      nickname: 'Tilltalsnamn',
      specific_discount: 'Specifik-rabatt',
      org_no: 'Organisationsnummer',
      address_1: 'Faktureringsadress1',
      address_2: 'Faktureringsadress2',
      zip_code: 'Postnummer',
      city: 'Stad',
      locked: 'Låst',
      low_credit_rating: 'Kreditvärdighet',
      agency_id: 'Byrå ID',
      phone_no: 'Telefon',
      payment_terms: 'Betalvillkorskod',

      // Discount fields
      ...this.discountList,

      // Campaign  fields
      _status: 'Status',
      cpm: 'CPM',
      creatives: 'material',
      goals: 'mål',
      campaign: 'kampanj',
      status: 'Status',
      custom_text: 'Kommentar',
      agency_self_service_details: 'Administrationsersättning',
      compensation_enabled: 'Kommer få ersättning',
      comment: 'Kommentar',
      reference: 'Referens',
      db_start_date: 'Kampanj startdatum',
      db_end_date: 'Kampanj sludatum',
      mms_gain: 'MMS-stärkning',
      booked_date: 'Bokningsdatum',
      client_invoice_reference: 'Referensperson',
      view_currency: 'Visningsvaluta',

      // Creatives
      ...this.creativesList,

      // Targetings
      ...this.targetingsList,

      // TTV Booking fields
      ...this.ttvBookingList,
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertVoucherConditions = (word: string): string => {
    const list = {
      product: 'Produktvillkor',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertRoles = (word: string): string => {
    const list = {
      accounting: 'Ekonomiansvarig',
      admin: 'Administratör',
      agency_admin: 'Byråadmin',
      agency_user: 'Byråanvändare',
      agency: 'Byråanvändare',
      cash_user: 'Cashkund',
      client_user: 'Direktkund',
      data_scientist: 'Dataforskare',
      external_planner: 'Extern planerare',
      eos: 'EOS-användare',
      mema_api_user: 'MeMa-användare',
      planner: 'Reklamplanerare',
      sales: 'Säljare',
      sales_manager: 'Säljchef',
    };
    return list[word] !== undefined ? list[word] : word[0].toUpperCase() + word.replace(/_/g, ' ').slice(1);
  };

  convertMessage = (word: string): string => {
    const list = {
      dimensions: 'bilddimensionen',
      incorrect_dimensions: 'bilddimensionen',
      pattern: 'filformatet',
      maxSize: 'filstorleken',
      file_size_too_large: 'filstorleken',
      maxDuration: 'filmlängden',
    };
    const translation = list[word];
    return translation !== undefined ? ' för ' + translation : '';
  };

  convertSlotTypes = (slotType: SlotType): string => {
    const translations = {
      [SlotType.preroll]: 'Preroll',
      [SlotType.midroll]: 'Midroll',
      [SlotType.postroll]: 'Postroll',
      [SlotType.prerollSticky]: 'Preroll Sticky',
      [SlotType.postrollSticky]: 'Postroll Sticky',
    };
    return translations[slotType] ? translations[slotType] : slotType;
  };

  convertCreativeStatus = (word: string): string => {
    const list = {
      not_ready: 'Inte komplett',
      ready: 'Klar',
      unknown: 'Okänt',
      peach_file_not_found: 'Misslyckades att hämta fil från Peach-servern',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertInventoryFilter = (word: string): string => {
    const list = {
      categories: 'Kategorier',
      publishers: 'Publicister',
      publisher_groups: 'Publicistgrupper',
      regions: 'Regioner',
      programs: 'Programformat',
      slot_types: 'Annonsplaceringar',
      targets: 'Målgrupper',
      device_groups: 'Apparater',
      week: 'Veckor',
      type: 'Kampanjtyp',
      pause_ad: 'Pausreklam',
      video_ad: 'Reklamfilm',
      film_length: 'Filmlängd',
      ages: 'Ålder',
      genders: 'Kön',
      behaviors: 'Beteendesegment',
      trades: 'Branscher',
      dayparts: 'Dayparts',
      frequency_limit: 'Frekvenstak',
      automated_guaranteed: 'Automated Guaranteed (VAST-länk)',
      advanced_target_groups: 'Avancerade målgrupper',
      product_formats: 'Produktformat',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertEditCreativesCriterium = (word: string): string => {
    const list = {
      wrongRole: '',
      wrongCampaignStatus: '',
      campaignStartTooSoon: 'Din kampanj är för nära inpå start för att få redigera materialet.',
      noCreatives: '',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertLoginError = (word: string): string => {
    const list = {
      Unauthorized: 'Fel inloggningsuppgifter.',
      'Not Allowed': 'Användaren är borttagen.',
      Locked: 'Kontot är låst. Försök igen om 1 minut.',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertProductChoice = (word: string): string => {
    const list = {
      rbs: 'RBS',
      category: 'Kategori',
      region: 'Region',
      publisher: 'Publicist',
      daypart: 'Daypart',
      deviceGroup: 'Apparat',
      device_group: 'Apparat',
      device: 'Apparat',
      advancedTargetGroup: 'Avancerad målgrupp',
      advanced_target_group: 'Avancerad målgrupp',
      targetGroup: 'Målgrupp',
      programFormat: 'Program',
      program_format: 'Program',
      program: 'Program',
      behavior: 'Beteendesegment',
      gender: 'Kön',
      age: 'Ålder',
      gender_and_age: 'Ålder + kön',
      noChoice: '-',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertFeatureToggles = (id: string, fallback: string): string => {
    const list = {};
    return list[id] !== undefined ? list[id] : fallback;
  };

  convertCreativeTypes = (type: string): string => {
    const list = {
      peach: 'Peach',
      automated_guaranteed: 'VAST 2.0 - 4.0',
      imd: 'IMD',
      adstream: 'Adstream',
      other: 'Annat leveranssätt',
      pause_image: 'Pausreklam',
    };
    return list[type] !== undefined ? list[type] : type;
  };

  convertCreativeTypesShort = (type: string): string => {
    const list = {
      automated_guaranteed: 'VAST 2.0 - 4.0',
    };
    return list[type] !== undefined ? list[type] : this.convertCreativeTypes(type);
  };

  convertVoucherConditionTypes = (type: string): string => {
    const list = {
      product: 'Produktvillkor',
    };
    return list[type] !== undefined ? list[type] : type;
  };

  convertShareTypes = (type: string): string => {
    const list = {
      percent: '%',
      impressions: 'comp.views',
      budget: 'kr',
    };
    return list[type] !== undefined ? list[type] : type;
  };

  convertFileUploadTypes = (type: string): string => {
    const list = {
      user_terms: 'Användarvillkor',
      cash_user_terms: 'Användarvillkor (cashkunder)',
      cookie_policy: 'Cookiepolicy',
      data_policy: 'Datapolicy',
      integrity_policy: 'Integritetspolicy',
      technical_spec_sv: 'Teknik & produktspecifikation (sv)',
      technical_spec_en: 'Teknik & produktspecifikation (en)',
      product_information: 'Produktinformation',
      material_guide: 'Materialguide',
      technical_spec_programmatic: 'Teknik & produktspecifikation (programmatiskt)',
    };
    return list[type] !== undefined ? list[type] : type;
  };

  convertGenderPlural = (word: string): string => {
    const list = {
      Kvinna: 'kvinnor',
      Man: 'män',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertCampaignStatus = (state: CampaignStatus): string | CampaignStatus => {
    const list = {
      [CampaignStatus.upcoming]: 'Godkänd',
      [CampaignStatus.reviewing]: 'Granskas',
      [CampaignStatus.rejected]: 'Ej godkänd',
      [CampaignStatus.stopped]: 'Stoppad',
      [CampaignStatus.cancelled]: 'Avbokad',
      [CampaignStatus.ended]: 'Avslutad',
      [CampaignStatus.unbooked]: 'Sparad',
    };
    return list[state] !== undefined ? list[state] : state;
  };

  convertErrorToEasyMessage = (error: string): string => {
    const list = {
      insufficient_available_inventory:
        'Det finns inte tillräckligt många tillgängliga visningar för att uppfylla dina önskemål.',
      no_inventory: 'Det finns inget lager för den valda kombinationen.',
    };
    const translation = list[error];
    return translation ? translation : this.convertError(error);
  };

  convertViewCurrency = (currency: ViewCurrency | CombinedViewCurrencies): string => {
    const list = {
      [ViewCurrency.impressions]: 'impressions',
      [ViewCurrency.completedViews]: 'completed views',
      [ViewCurrency.grossRatingViews]: 'bruttokontakter',
      [CombinedViewCurrencies.impcv]: 'imp./compl. views',
      [CombinedViewCurrencies.impgrv]: 'imp./b. kontakter',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  convertViewCurrencyToInventoryCurrency = (currency: ViewCurrency): string => {
    const list = {
      [ViewCurrency.impressions]: 'impressions',
      [ViewCurrency.completedViews]: 'completed views',
      [ViewCurrency.grossRatingViews]: 'impressions',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  convertViewCurrencyShort = (currency: ViewCurrency | CombinedViewCurrencies): string => {
    const list = {
      [ViewCurrency.impressions]: 'imp.',
      [ViewCurrency.completedViews]: 'compl. views',
      [ViewCurrency.grossRatingViews]: 'b. kontakter',
      [CombinedViewCurrencies.impcv]: 'imp./compl. views',
      [CombinedViewCurrencies.impgrv]: 'imp./b. kontakter',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  convertViewCurrencyExtraShort = (currency: ViewCurrency | CombinedViewCurrencies): string => {
    const list = {
      [ViewCurrency.impressions]: 'imp.',
      [ViewCurrency.completedViews]: 'CV',
      [ViewCurrency.grossRatingViews]: 'BK',
      [CombinedViewCurrencies.impcv]: 'imp./CV',
      [CombinedViewCurrencies.impgrv]: 'imp./BK',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  convertViewCurrencyToDescription = (currency: ViewCurrency): string => {
    const list = {
      [ViewCurrency.impressions]: 'Cost Per 1000 Impressions',
      [ViewCurrency.completedViews]: 'Cost Per Completed View',
      [ViewCurrency.grossRatingViews]: 'Cost Per Bruttokontakt',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  convertViewCurrencyToAcronym = (currency: ViewCurrency | CombinedViewCurrencies): string => {
    const list = {
      [ViewCurrency.impressions]: 'CPM',
      [ViewCurrency.completedViews]: 'CPCV',
      [ViewCurrency.grossRatingViews]: 'CPT',
      [CombinedViewCurrencies.impgrv]: 'CPM/CPT',
      [CombinedViewCurrencies.impcv]: 'CPM/CPCV',
    };
    return list[currency] !== undefined ? list[currency] : currency;
  };

  translateViewCurrency = (currency: ViewCurrency): string => {
    const list = {
      [ViewCurrency.impressions]: 'Impressions',
      [ViewCurrency.completedViews]: 'Completed views',
      [ViewCurrency.grossRatingViews]: 'Bruttokontakter',
    };

    return list[currency] !== undefined ? list[currency] : `Finns ingen text för ${currency}`;
  };

  convertBookingFormatForCashCampaign = (type: string): string => {
    const list = {
      region: 'Område',
      publisherGroup: 'Publicist',
      targetGroup: 'Målgrupp',
      rbs: 'Ingen',
      publisher: 'Ingen',
      'publisher-region': 'Område',
      'publisher-targetGroup': 'Målgrupp',
    };
    return list[type] !== undefined ? list[type] : type;
  };

  convertSendToAdserverError = (error: string): string => {
    const list = {
      validation_failed: 'Kampanjen är inte redo för att skickas till adservern.',
      campaign_must_be_booked: 'En sparad kampanj kan inte skickas till adservern.',
      campaign_cannot_be_inventory_only: 'Bortbokningar får ej skickas till adservern',
      active_campaign_cannot_be_overwritten:
        'Kampanjen kunde inte skickas till adservern, den har redan startat och är aktiv på adservern.',
      no_available_period_for_program_format: 'Ett programformat saknar programperiod',

      //MrmDataError
      ad_unit_id_can_not_be_blank: 'Det saknas ad_unit_id',
      ad_unit_id_is_not_integer: 'ad_unit_id är inte ett heltal',
      ad_unit_package_id_not_integer: 'ad_unit_package_id är inte ett heltal',
      ad_unit_package_name_cannot_be_blank: 'Det saknas namn för ad_unit_package_id',
      ad_unit_status_can_not_be_blank: 'Det saknas status för ad_unit',
      audience_items_cannot_be_blank: 'Det saknas audience_items',
      budget_model_cannot_be_blank: 'Budgetmodell saknas',
      campaign_advertiser_id_cannot_be_blank: 'Kampanjen saknar en kund',
      campaign_external_id_cannot_be_blank: 'Kampanjen saknar ett externt ID',
      campaign_mrm_id_cannot_be_blank: 'Kampanjen saknar MRM ID',
      campaign_name_cannot_be_blank: 'Kampanjen saknar namn',
      cpm_price_must_be_positive: 'CPM-priset måste vara mer än 0',
      creative_ids_must_be_unique: 'En creative måste ha ett unikt ID',
      custom_pacing_requires_pacing_points: 'Det saknas pacing_points för custom_pacing',
      daypart_minutes_not_supported: 'Daypart stödjer inte minuter',
      delivery_node_needs_frequency_cap: 'Det saknas frequency_cap på delivery_node',
      event_type_cannot_be_blank: 'Det saknas event_type',
      flighting_are_not_equal_and_overlap: 'Ogiltig flighting',
      frequency_cap_node_needs_period: 'Det saknas period på frequency_cap_node',
      frequency_cap_node_needs_type: 'Det saknas typ på frequency_cap_node',
      frequency_cap_node_needs_value: 'Det saknas värde på frequency_cap_node',
      frequency_cap_period_invalid: 'Ogiltig period för frequency_cap',
      frequency_cap_type_invalid: 'Ogiltig typ för frequency_cap',
      impression_cannot_be_blank: 'Det saknas impressions',
      impression_cap_is_not_integer: 'Impression_cap är inte ett heltal',
      include_cannot_be_blank: 'Include saknas',
      insertion_order_id_cannot_be_blank: 'Insertion ordern saknar ID',
      insertion_order_name_cannot_be_blank: 'Insertion Ordern saknar namn',
      invalid_budget_model: 'Ogiltig budgetmodell',
      invalid_display_linking_scenario: 'Ogiltig display_linking_scenario',
      invalid_impression_value: 'Ogiltigt värde för impressions',
      invalid_instream_linking_scenario: 'Ogiltig instream_linking_scenario',
      invalid_link_method_for_custom_set: 'Ogiltig link_method för custom_set',
      invalid_link_method_on_ad_product: 'Ogiltig link_method för ad_product',
      invalid_overlay_linking_scenario: 'Ogiltig overlay_linking_scenario',
      invalid_placement_type_on_placement: 'Ogiltig placement_type för placement:en',
      invalid_relation: 'Ogiltig relation',
      invalid_scheduling_method: 'Ogiltig scheduling_method',
      invalid_status_for_ad_unit_node: 'Ogiltig status på ad_unit_node',
      is_click_through_cannot_be_blank: 'Värde saknas på is_click_through',
      link_method_cannot_be_blank: 'Ad product saknar link_method',
      metric_name_cannot_be_blank: 'Det saknas metric_name',
      must_include_scheduling_method: 'Det saknas en scheduling_method',
      parts_cannot_be_blank: 'Daypart saknar parts',
      placement_external_id_cannot_be_blank: 'Det finns inget WOO ID för placement:en',
      placement_name_cannot_be_blank: 'Det finns inget namn på placement:en',
      sets_cannot_be_blank: 'Det saknas set',
      schedule_end_time_cannot_be_blank: 'Schedule saknar sluttid',
      schedule_end_time_cannot_be_earlier_than_start_time: 'Sluttiden kan inte vara före starttiden',
      schedule_start_time_cannot_be_blank: 'Schedule saknar starttid',
      schedule_time_zone_cannot_be_blank: 'Schedule saknar tidszon',
      start_or_end_time_cannot_be_parsed: 'Start- eller sluttid kan inte parsas',
      time_zone_cannot_be_blank: 'Daypart saknar tidszon',
      unknown_daypart_hour: 'Okänd daypart-timma',
      unknown_frequency_period: 'Okänd frekvensperiod',
      url_cannot_be_blank: 'Det saknas en URL',
      weights_do_not_sum_to_one_for_periods: 'Viktningen summeras inte till 100%',
      wrong_link_method_used_with_advanced_linking: 'Fel linkningsmetod används med advanced_linking',
      wrong_link_method_used_with_custom_set: 'Fel linkningsmetod används med custom_set',
      invalid_media_type: 'Icke giltigt media format - dubbelkolla så att creative URL faktiskt leder till en creative',
    };
    const defaultMessage = 'Ajdå, något gick snett. Kunde inte skicka till adservern.';
    return list[error] !== undefined ? list[error] : `${defaultMessage} \n ${error}`;
  };

  convertUpdateGoalsError = (error: string): string => {
    const translations = {
      [GoalUpdateBookingError.insufficientInventory]:
        "De extra visningar som du lagt till får inte plats i WOO's lager.",
      [GoalUpdateBookingError.goalTotalImpressionsBelowZero]:
        'Uppdateringen misslyckades. Negativa mål är inte tillåtna.',
      [GoalUpdateBookingError.goalUpdateSomeFailed]: 'Alla mål kunde inte uppdateras. WOO har ändrat i sitt lager.',
      [GoalUpdateBookingError.goalUpdateAllFailed]: 'Inget mål kunde uppdateras.',
      [GoalUpdateBookingError.unspecifiedError]: 'Uppdateringen misslyckades. WOO kunde inte uppdatera målen.',
    };

    return translations[error] !== undefined
      ? translations[error]
      : translations[GoalUpdateBookingError.unspecifiedError];
  };

  convertDate = (month: Date): { month: ReturnType<TranslationService['convertMonthIndex']> } => {
    return {
      month: this.convertMonthIndex(month.getMonth()),
    };
  };

  convertMonthIndex = (monthIndex: number): { full: string } => {
    const fullMonthNames = [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ];
    return { full: fullMonthNames[monthIndex] };
  };

  convertFrequencyLimit(limit: FrequencyLimit): string {
    if (limit.time_unit === FrequencyLimitTimeUnit.campaign) {
      return `${limit.impressions} för hela kampanjen`;
    } else if (limit.time_unit === FrequencyLimitTimeUnit.week) {
      return `${limit.impressions} per vecka`;
    } else {
      return limit.name;
    }
  }

  convertAttestStatus = (attestStatus: string): string => {
    const list = {
      attested_ok: 'Godkänd',
      awaiting_attest: 'Väntar på attestering',
      pre_attest_rules: 'Skickad innan attestregler',
      no_attest_needed: 'Behöver ej attesteras',
    };
    return list[attestStatus] !== undefined ? list[attestStatus] : attestStatus;
  };

  convertProductFormat = (format: string): string => {
    const formats = {
      longform: 'Adresserbar TV',
      'TV4 Long': 'Adresserbar TV',
      shortform: 'Online Video',
      'TV4 Short': 'Online Video',
      combination: 'Kombination',
      pause_longform: 'Adresserbar TV Paus',
      pause_shortform: 'Online Video Paus',
      pause_combination: 'Kombination Paus',
      includes_linear: 'Regionalpaketering',
    };
    return formats[format] !== undefined ? formats[format] : format;
  };

  convertAgePriceListKey = (ageKey: string): string => {
    const withoutPrefix = ageKey.replace('age_', '');
    return withoutPrefix.slice(0, 2) + '-' + withoutPrefix.slice(2);
  };

  convertLocality = (locality: string): string => {
    const localities = {
      national: 'National',
      regional: 'Regional',
    };
    return localities[locality] !== undefined ? localities[locality] : locality;
  };

  convertFrequencyLimitTimeUnit = (timeunit: string): string => {
    const timeUnits = {
      campaign: 'Kampanj',
      week: 'Vecka',
      half_hour: '30min',
    };
    return timeUnits[timeunit] !== undefined ? timeUnits[timeunit] : timeunit;
  };

  convertEosCampaignRow = (word: any): any => {
    const list = {
      advanced_target_groups: 'Avancerade målgrupper',
      agency: 'Mediebyrå',
      agency_discount_actual: 'Faktisk mediebyråersättning',
      agency_discount_estimate: 'Estimerad mediebyråersättning',
      agreement_date: 'Avtalsdatum',
      agresso_id: 'Agresso-Id',
      behaviors: 'Beteenden',
      booking_organisation: 'Bokad av',
      campaign_id: 'Kampanj-Id',
      campaign_name: 'Namn',
      campaign_end_date: 'Slutdatum',
      campaign_start_date: 'Startdatum',
      campaign_status: 'Status',
      category: 'Kategori',
      creative_lengths: 'Filmlängder',
      customer_crm_id: 'CRM-Id',
      date: 'Datum',
      dayparts: 'Dayparts',
      device_groups: 'Enhetsgrupper',
      devices: 'Enheter',
      frequency_limit: 'Frekvenstak',
      gross_estimate: 'Est. Brutto',
      impressions_actual: 'Utf. impressions',
      impressions_actual_mms: 'Utf. MMS impressions',
      gross_rating_views_actual_mms: 'Utf. MMS bruttokontakter',
      impressions_additional_estimate: 'Est. Extra impressions',
      impressions_estimate: 'Est. impressions',
      gross_rating_views_additional_estimate: 'Est. Extra bruttokontakter',
      gross_rating_views_estimate: 'Est. bruttokontakter',
      invoice_date: 'Fakturadatum',
      invoice_sent: 'Faktura har skickats',
      invoice_total: 'Fakturerat Belopp',
      net_actual: 'Utf. Netto',
      net_cpm: 'Netto-CPM',
      net_estimate: 'Est. Netto',
      net_net_actual: 'Utf. Net-Netto',
      net_net_estimate: 'Est. Net-Netto',
      net_publisher_kickback: 'Andel publicist',
      net_tv4_kickback: 'Andel TV4',
      product: 'Produktnamn',
      product_format: 'CRAM Produktformat',
      product_category: 'CRAM Produktkategori',
      program_formats: 'Programformat',
      publisher: 'Publicist',
      publisher_combination: 'Köpt publicist',
      placement: 'Reklamfilmspaket',
      region: 'Region',
      regional_type: 'CRAM Geografi',
      sale_type: 'Säljtyp',
      send_invoices: 'Faktura ska skickas',
      so_number: 'Sales order nummer',
      target_groups: 'Målgrupper',
      targeting_type: 'CRAM Annonstyp',
      external_reference_id: 'Externt referens ID',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertEosTTVCampaignRow = (word: any): string => {
    const list = {
      agency: 'Mediebyrå',
      campaign_id: 'Kampanj-Id',
      campaign_name: 'Namn',
      campaign_end_date: 'Slutdatum',
      campaign_start_date: 'Startdatum',
      campaign_status: 'Status',
      creative_length: 'Filmlängder',
      customer_crm_id: 'CRM-Id',
      date: 'Datum',
      impressions_actual_mms: 'Levererade impr. enligt MMS',
      gross_rating_views_actual_mms: 'Levererade b. kontakter enligt MMS',
      impressions_actual_pulse: 'Levererade impr. från adservern',
      impressions_adjusted: 'Ökat/minskat mål (CVs)',
      impressions_estimate: 'Est. impressions',
      impressions_estimate_ttv: 'Uträknade impressions',
      gross_rating_views_estimate_ttv: 'Bruttokontakter',
      mms_gain_factor: 'MMS-stärkning',
      net_estimate: 'Est. Netto',
      net_estimate_ttv: 'Spotlight nettobudget',
      placement: 'Reklamfilmspaket',
      ttv_reference_number: 'Spotlight referensnummer',
      ttv_booking_id: 'TTV-boknings-ID',
      ttv_booking_name: 'Spotlight bokningsnamn',
      ttv_mapping_factor: 'Översättningsfaktor',
      ttv_target_group: 'Total-TV-målgrupp',
      universe: 'Universumtal',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertInventoryRow = (word: any): string => {
    const list = {
      date: 'Datum',
      region: 'Region',
      estimated: 'Estimerat',
      available: 'Tillängligt',
      withdrawal_limit: 'Maxuttag',
      slot_type: 'Slot',
      publisher: 'Publicist',
      ttv_target_group: 'TTV-målgrupp',
      channel_name: 'Kanal',
    };
    return list[word] !== undefined ? list[word] : word;
  };

  convertError = (error: any): string => {
    const translations = {
      'is invalid': 'är ogiltig',
      'Woo internal error': 'Ett internt fel uppstod',
      'woo internal error': 'Ett internt fel uppstod',
      [ServerEvents.serviceUnavailableError]: 'WOO svarar visst inte, prova igen om en liten stund.',
      invalid_parameters: 'Felaktiga parametrar',
      invalid_mms_gain: 'Värdet för MMS-stärkningen är ogiltigt',
      access_missing: 'Behörighet saknas.',
      cant_remove_yourself: 'Du kan inte ta bort dig själv.',
      rate_limit_exceeded: 'Du kan bara göra ett anrop i taget.',
      invalid_dates: 'Felaktiga datum',
      start_date_too_early: 'Ditt startdatum är för nära i tiden.',
      duplicate_campaign: 'Kampanjen är för lik en tidigare kampanj. Dubbletter är inte tillåtna.',
      client_invoice_reference_missing: 'Fältet för er referens är tomt.',
      campaign_name_missing: 'Kampanjen saknar namn.',
      reference_number_missing: 'Kampanjen saknar PO-nr/AO-nr/internt referensnummer.',
      invalid_share: 'Den totala fördelningen måste vara 100%',
      invalid_share_value: 'Estimatfördelning måste vara mindre än 100 samt 0 eller mer',
      invalid_withdrawal_limits_value: 'Maxuttag måste vara mindre än 100 samt 0 eller mer',
      invalid_distribution_of_publisher_shares: 'Ogiltig publicist fördelning',
      insufficient_estimated_inventory: 'Din kampanj går tyvärr inte att boka. Vänligen kontakta supporten.',
      unableToBook: 'Din kampanj går tyvärr inte att boka. Vänligen kontakta supporten.',
      insufficient_inventory_for_quotas: 'Din kampanj går tyvärr inte att boka.',
      insufficient_available_inventory: 'Din kampanj går tyvärr inte att boka.',
      no_inventory: 'Det finns inget lager ännu för den valda perioden.',
      cannot_create_order: 'Din kampanj går tyvärr inte att boka, den valda kombinationen är inte tillåten.',
      impossible_creative_and_publisher_shares_combination:
        'Din kampanj går tyvärr inte att boka. Det är kombinationen av filmer och publicistfördelning som ställer till det.',
      impossible_creative_and_publisher_group_shares_combination:
        'Din kampanj går tyvärr inte att boka. Det är kombinationen av filmer och publicistgruppsfördelning som ställer till det.',
      inventories_sold_out: 'Din kampanj går tyvärr inte att boka. En eller flera valda kombinationer är slutsålda.',
      insufficient_inventory: 'Din kampanj sparades, men gick tyvärr inte att boka.',
      insufficient_inventory_when_updating: 'Uppdateringen på kampanjen gick tyvärr inte att genomföra.',
      creatives_type_mismatch: 'Dina filmer har olika leveranssätt. WOO stödjer bara ett leveranssätt per kampanj.',
      too_few_impressions:
        'Dina val är lite för snäva för denna kampanj. Kampanjen uppnår inte till minsta antal visningar som WOO kräver.',
      too_large_budget_2:
        'Din budget i den här kampanjen är tyvärr för stor. Prova att ändra publicist, kategori, region eller filmlängd.',
      campaign_too_short:
        'Det där blev lite för kort. Din kampanj måste sträcka sig över minst ' +
        this.systemService.variables.min_campaign_length +
        ' dagar.',
      campaign_too_early:
        'Din kampanj ligger för nära i tiden. Den måste bokas minst ' +
        this.systemService.variables.min_days_until_start +
        ' dagar innan kampanjstart.',
      campaign_too_early_low_credit_rating: 'Du kan tyvärr inte boka kampanjen, kontakta supporten.',
      campaign_is_not_approved:
        'Du kan inte ladda ner en bokningsbekräftelse för en kampanj vars bokning inte godkänts.',
      too_large_budget: 'Din maxbudget ligger utanför WOOs budgetram.',
      negative_budget: 'Vi vill inte vara negativa. Men kan ett minustecken ha smygit sig in framför din budget?',
      budget: 'WOOs budgetram ligger mellan 0 och ' + this.systemService.variables.max_allowed_budget + ' kronor.',
      budget_not_set: 'Budget måste överstiga 0 kronor såvida inte extra budget är satt',
      creatives_quotas_error: 'Var uppmärksam på procenten. Fördelningen mellan dina filmer är inte helt 100.',
      creative_quota_error: 'Kontrollera fördelningen mellan dina filmer, det är något som inte stämmer.',
      creative_without_segment: 'En av dina filmer saknar period för när den ska gå.',
      creative_without_length: 'En av dina filmer saknar längd.',
      creative_change_too_late: 'Du kan tyvärr inte redigera material eftersom att det är för nära inpå kampanjstart',
      creative_length_change_not_allowed: 'Filmkoden stämmer inte överens med filmlängden.',
      creative_length_does_not_match_code: 'Filmkoden stämmer inte överens med filmlängden.',
      no_price_list_for_campaign_period: 'Det finns tyvärr ingen prislista för vald period.',
      no_price_for_product: 'Det saknas ett pris i prislistan för den valda produkten',

      creativeBudgetError: 'Budgeten för filmer överstiger kampanjens budget.',
      creativeBudgetTooSmall: 'Filmernas summerade budgets uppgår inte till kampanjens totala budget.',
      creativeBudgetsNotAllZeroOrSet: 'Minst en av filmerna saknar budget.',

      regionalBudgetError: 'Budgeten för regioner överstiger kampanjens budget.',
      regionalBudgetTooSmall: 'Regionernas summerade budgets uppgår inte till kampanjens totala budget.',
      regionalImpressionsBudgetError: 'Regionernas summerade visningar överstiger kampanjens totala visningar.',
      regionalImpressionsBudgetTooSmall:
        'Regionernas summerade visningar uppgår inte till kampanjens totala visningar.',
      regional_impressions_mismatch: 'Regionernas fördelning går inte jämnt ut.',
      regional_budgets_mismatch: 'Regionernas sammanlagda budgetar måste vara lika med den totala budgeten.',
      regional_budgets_not_all_zero_or_set: 'Minst en av regionerna saknar budget.',
      regional_impressions_not_all_zero_or_set: 'Minst en av regionerna saknar visningar.',

      publisherPercentError: 'Du har fördelat mer än 100 %.',
      publisherPercentTooSmall: 'Du har inte fördelat alla 100 %.',
      publisher_percent_not_all_zero_or_set: 'Minst en av publicisterna saknar fördelning.',
      invalid_publisher_groups_quotas: 'Fördelningen mellan publisistgrupperna är inte rätt.',

      formatBudgetError: 'Budgeten för program överstiger kampanjens budget.',
      formatBudgetTooSmall: 'Programmens summerade budgets uppgår inte till kampanjens totala budget.',
      formatImpressionsBudgetError: 'Programmens summerade visningar överstiger kampanjens totala visningar.',
      formatImpressionsBudgetTooSmall: 'Programmens summerade visningar uppgår inte till kampanjens totala visningar.',
      program_format_budgets_mismatch: 'Programmens fördelning går inte jämnt ut.',
      program_format_impressions_not_all_zero_or_set: 'Minst ett av programmen saknar visningar.',
      program_format_budgets_not_all_zero_or_set: 'Minst ett av programmen saknar budget.',

      earlyCampaignStart:
        'Var noga med att fylla i alla obligatoriska uppgifter så vi hinner få allt på plats till kampanjstarten.',

      notAllowedToChooseAllDeviceGroups: 'Du kan inte välja alla apparater.',

      no_valid_partner_agreement: 'Kunden saknar giltigt partneravtal med byrån för den period du försöker boka',
      campaign_already_invoiced: 'Ändringar som påverkar kampanjens fakturerade belopp är inte tillåtna.',

      must_have_discount: 'Kampanjen måste innefatta rabatt för kampanjens samtliga dagar.',

      voucher_condition_product: 'Ad engångs-rabatten du valde är inte giltig för kampanjens styrning..',
      voucher_incompatible: 'Ad engångs-rabatten du valde är inte giltig för kampanjens styrning.',
      voucher_already_consumed: 'Ad engångs-rabatten du valde används redan på en annan kampanj.',
      wrong_agency_for_voucher: 'Ad engångs-rabatten du valde är inte giltig för den valda byrån.',

      noGenderOrAgeError: 'Du behöver välja minst antingen kön eller ålder att styra på för målgruppen.',

      region: 'regioner',
      program_format: 'program',
      category: 'kategorier',
      target_group: 'målgrupper',
      publisher: 'publicister',
      inventory_start_date_too_early: 'Tyvärr, vi har bara lager för de senaste 12 månaderna.',
      email_already_taken: 'Det finns redan en användare i WOO Manager med den mailadressen',
      email_already_taken_by_inactive_user: 'Det finns redan en borttagen användare i WOO Manager med den mailadressen',

      cannot_combine_film_length_filter_with_pause_ad: 'Du kan inte filtrera på filmlängd och pausreklam samtidigt.',
      invalid_film_length: 'Filmlängden är du angav stöds inte i WOO Manager.',
      cannot_combine_slot_type_with_film_length: 'Du kan inte filtrera på filmlängd och annonsplacering samtidigt.',

      missing_year_in_filename: 'Kunde ej läsa ut årtal ur filnamnet.',
      no_estimate_file_to_import: 'Det finns inga estimatfiler att importera om.',

      too_many_files: 'Kunden har för många filer upladdade. Ta bort några för att ladda upp nya.',

      week_end_before_week_start: 'Slutveckan är före startveckan.',

      invalid_current_password: 'Du angav felaktigt nuvarande lösenord.',
      creative_asset_url_used_in_campaign: 'Gick inte att ta bort resursen eftersom den används i en kampanj.',
      wait_for_job_to_complete: 'Försök lite senare, vi har fullt upp just nu.',

      can_not_convert_customer_with_users: 'Kunder med användare kan inte göras om till cashkunder',
      can_not_convert_customer_with_agency: 'Kunder knutna till en agency kan inte göras om till cashkunder',
      unsupported_cpcv_calculation: 'Cashkampanjer har inte stöd för flera filmer/bilder eller styrningar',
      signup_failed_creation:
        'Nedrans, det gick inte att spara. Försök igen senare eller skicka ett mail till info@woo.ad.',
      ad_server_campaign_modified: 'Kampanjen verkar ha redigerats manuellt på adservern',

      can_not_destroy_the_active_ttv_targeting_mapping: 'Du kan inte ta bort en aktiv TTV-inställning.',
      can_not_destroy_old_ttv_targeting_mappings: 'Du kan inte ta bort en gammal TTV-inställning.',

      brand_does_not_belong_to_customer: 'Varumärket tillhör ej kund',

      invalid_targeting_for_disney: 'Disney+ kan ej bokas som regional eller pausreklam',

      missing_sales_order_number: 'Salesordernummer behövs för att kunna boka regionalpaketering',
      cant_have_creatives_longer_than_20s_on_shortformat: 'Online Video går ej att boka med filmer längre än 20s',
      must_have_at_least_one_creative_20s_or_shorter_on_combination:
        'Kombinationsbokning ej möjlig, det saknas film som är 20s eller kortare',

      // Extend campaign
      not_allowed_to_extend_this_many_days: `Du får bara förlänga kampanjen ${this.systemService.variables.day_to_expand_a_campaign_targeting} dagar.`,
      cannot_extend_includes_linear_targeting_across_turn_of_the_year:
        'Regionalpaketerade kampanjer får ej sträcka sig över årsskiftet 2022/2023',
      pending_eos_rows_rebuild:
        'Kampanjen kan tyvärr inte förlängas förrän Eos-raderna har byggts om, försök igen imorgon.',
      shares_do_not_sum_to_one: 'Estimatfördelningen mellan produktformaten måste summeras till 1',

      must_have_one_default_in_category: 'Det får bara finnas en default template för varje kategori',

      // Removed features
      cash_campaigns_are_no_longer_bookable: 'Det går tyvärr inte att boka cashkampanjer längre',
      ip_based_targeting_no_longer_supported: 'Det går tyvärr inte att skapa regionala kampanjer med IP-adress längre',

      //Error messages
      campaign_has_eos_rows_and_can_not_be_removed:
        'Kampanjen har EOS-rader och kan därför inte tas bort, prova stänga den istället.',
      campaign_is_cancelled_and_can_no_longer_be_edited: 'Kampanjen är avbokad och kan därför inte längre redigeras.',
      campaign_is_stopped_and_can_no_longer_be_edited: 'Kampanjen är stoppad och kan därför inte längre redigeras.',
      campaign_is_cancelled_and_can_no_longer_be_sent_to_pulse:
        'Kampanjen är avbokad och kan därför inte skickas till adservern.',
      campaign_is_stopped_and_can_no_longer_be_sent_to_pulse:
        'Kampanjen är stoppad och kan därför inte skickas till adservern.',
      campaign_is_already_cancelled: 'Kampanjen är redan avbokad.',
      campaign_is_ended_and_cannot_be_cancelled: 'Kampanjen är avslutad och kan därför inte avbokas.',
      campaign_is_ongoing_and_cannot_be_cancelled: 'Kampanjen är rullande och kan därför inte avbokas.',
      campaign_is_stopped_and_cannot_be_cancelled: 'Kampanjen är stoppad och kan därför inte avbokas.',
      campaign_is_no_longer_ongoing_and_cannot_be_updated:
        'Kampanjen är inte längre rullande och kan därför inte uppdateras.',
      campaign_is_not_booked: 'Kampanjen är inte bokad och kan därför inte bokas om',
      cancel_non_booked_campaign: 'Bara bokade kampanjer kan avbokas.',
      wrong_campaign_status_error: 'Kampanjen har fel status',

      // MRM
      targeting_spans_break_date: 'Kampanj går ej att boka över valt datum, se inforuta för datumväljaren',
      targeting_extends_over_break_date:
        'Kampanj går ej att förlänga över valt datum då de passerar brytdatum för adserver',

      // Add creative to ongoing campaign
      attempting_to_connect_to_wrong_ad_server: 'Kampanj tillhör en annan adserver än den du försöker kontakta',
      invalid_placement_choice: 'Finns inga placements med detta ID',
      peach_video_not_found: 'Vald film finns inte hos Peach',
      missing_video_code_or_url: 'Creative saknar URL eller videokod',
      creatives_do_not_span_entire_placement_span: 'Filmernas individuella datum täcker inte hela placementens datum',
      cannot_resolve_remote_creative_structure: 'Synk-fel mellan WOO creatives och MRM creatives',
    };

    const fallBackError = 'Hoppsan, något gick fel! ';

    return translations[error] !== undefined ? translations[error] : fallBackError + error;
  };

  convertValidationError = (error: string): string => {
    const translations = {
      invalid_parameters: 'Felaktiga parametrar',

      // Model
      AgencySelfServiceDetails: 'Mediabyråns självbetjäningsuppgifter',
      AgreementPart: 'Avtalsdel',
      Campaign: 'Kampanj',
      Creative: 'Mediaobjekt',
      CreativeVideo: 'Film',
      CreativeImage: 'Pausreklam',
      Invoice: 'Faktura',
      Publisher: 'Publicist',
      Targeting: 'Styrning',
      TargetingPublisherGroup: 'Publicistgrupp',
      TargetingRegion: 'Region',
      TTVBooking: 'TTV bookning',
      Voucher: 'Ad Hoc engångsrabatt',

      // Keys
      additional_budget_message: ' Extra budget meddelande',
      budget: ' Budget',
      commercial_planner: ' Reklamplannerare',
      compensation: ' Kompensation',
      end_date: ' Slut datum',
      gross_cpm: ' Brutto CPM',
      impressions: ' Impressions',
      name: ' Namn',
      parent_error: ' Specifikt fel',
      rows: ' Rader',
      start_date: ' Start datum',
      targeting_mapping: ' TTV styrnings mappning',
      quota: ' Fördelning',
      region_group: ' Sammanslagna Regioner',
      frequency_limit: 'Frekvenstak',

      // Error messages
      'agency not tied to customer': 'Byrån är inte knuten till kunden',
      'Age span cannot be less than 10 years': ' Åldersspannet kan inte vara minder än 10 år',
      "can't be blank": ' Kan inte vara tom',
      'cannot be present without asset_url': ' Kan inte vara tillgänglig utan asset_url',
      'cannot occur before period start': ' Kan inte inträffa innan period start',
      'complexity requirements not met': ' Komplexitetskrav är inte uppnåt',
      'Debitfaktura måste ha positivt belopp': 'Debitfaktura måste ha positivt belopp',
      'Frequency limit hash must include :time_unit and :impressions':
        ' Frekvenstaket måste innehålla tidsenhet och impressions',
      'Frequency limit :impressions must be greater than zero': ' Frekvenstakets impressions måste vara större än noll',
      'Frequency limit :impressions must be an Integer': ' Frekvenstakets impressions måste vara en integer',
      'Invalid array content': ' Produkten är inte rabatterad även fast den ska vara det',
      'Invoices are not allowed for total-tv campaigns': 'Fakturor är inte tillåtna på ttv-kampanjer',
      'is not a number': ' Är inte ett heltal',
      'Kreditfaktura måste ha negativt belopp': 'Kreditfaktura måste ha negativt belopp',
      'Malformed date structure': ' Felaktig datumstruktur',
      'must be greater than or equal to 0': ' Måste vara större eller lika med 0',
      'Must be a member of an ad organisation': ' Måste vara medlem av en media organisation',
      'Must be set for agency role': ' Mediabyrån måste ha satt admin rättigheter',
      'Must have resource for agency user role': ' Mediabyråanvändare måste ha mediabyrå roll',
      'must have creative': 'Måste ha aktivt material vid kampanjens start och slut',
      'Not allowed to only select an age': ' Inte tillåten att bara sätta en ålder',
      'Not allowed to select all genders, without any age target groups':
        ' Inte tillåten att sätta kön utan någon målgrupp',
      'Only one expiry date allowed': ' Bara ett utgångsdatum är tillåtet',
      'Frequency not valid for targeting type': 'Frekvenstak ej giltigt för styrningsparametrar',
      age_span_must_not_have_gaps: ' Åldersspannet får inte ha några gap',
      budget_not_equal_total: ' Budgeten summerar inte till totalen',
      cant_combine_device_groups_with_other_options: ' Kan inte kombinera apparater med andra styrningar',
      cannot_combine_publishers_with_publisher_groups: ' Kan inte kombinera publicister med publicistgrupp',
      creative_without_segment: 'En av dina filmer saknar period för när den ska gå.',
      empty: ' Tom',
      incorrect_type: ' Fel typ på impression-tracking länken',
      incorrect_number: ' Felaktigt nummer',
      invalid_dates: ' Felaktiga datum',
      must_configure_all_video_slot_types: ' Man måste configurera alla video-slot-typer',
      not_a_number: ' Inte ett nummer',
      out_of_range: ' Utanför tillåtna spannet',
      overlapping: ' Det finns överlappande segment',
      program_format_budgets_mismatch: 'Programmens fördelning går inte jämnt ut.',
      program_format_budgets_not_all_zero_or_set: ' Minst ett av programmen saknar budget.',
      program_format_impressions_mismatch: ' Programmens fördelning går inte jämnt ut',
      program_format_impressions_not_all_zero_or_set: ' Minst ett av programmen saknar visningar.',
      publisher_group_percentages_do_not_aggregate_correctly:
        ' Publicistgruppernas procentuella fördelning aggregeras inte korrekt',
      publisher_group_percentages_greater_than_zero:
        ' Någon av Publicistgruppernas procentuella fördelning är inte större än noll',
      publisher_percentages_do_not_aggregate_correctly:
        ' Publicisternas procentuella fördelning aggregeras inte korrekt',
      publisher_percentages_unspecified_or_greater_than_zero:
        ' Någon av Publicisternas procentuella fördelning är inte större än noll',
      quotas_do_not_total_100: ' Fördelningen summerar inte till 100',
      quota_outside_span: ' Fördelningen är utanför det tillåtna spannet',
      regional_budgets_mismatch: ' Regionernas sammanlagda budgetar måste vara lika med den totala budgeten.',
      regional_budgets_not_all_zero_or_set: ' Minst en av regionerna saknar budget.',
      regional_impressions_mismatch: ' Regionernas fördelning går inte jämnt ut.',
      regional_impressions_not_all_zero_or_set: ' Minst en av regionerna saknar visningar.',
      same_object_id: ' Samma id existerar på någon av segementen',
      set_prematurely: ' Prislistan är satt för tidigt',
      share_type_mismatch: ' Programmens fördelningstyp är inte korrekt satt',
      start_date_after_end_date: ' Startdatumet är efter slutdatumet',
      start_date_too_early: ' Ditt startdatum är för nära i tiden.',
      too_soon: ' För tidigt',
      video_code_missing: ' Filmkod saknas',
      wrong_agency_for_voucher: ' Ad engångs-rabatten du valde är inte giltig för den valda byrån.',
      wrong_customer_for_voucher: ' Ad engångs-rabatten du valde är inte giltig för den valda kunden.',
      campaign_is_not_editable: 'Kampanjen går inte att uppdatera eftersom det ekonomiska utfallet redan är genererat!',

      only_campaigns_starting_after_break_day_can_have_region_group:
        'Enbart kampanjer startade 2023 kan bestå av sammanslagna regioner',
      region_group_not_yet_enabled: 'Bokning med sammanslagna regioner ej ännu aktiverad',
      includes_linear_not_bookable_in_2023_until_region_group_is_enabled:
        'Regionalpaketerade kampanjer är ej bokningsbara förän sammanslagna regioner är aktiverade',
      only_includes_linear_can_have_region_group:
        'Enbart regionalpaketerade kampanjer kan bokas med sammanslagna regioner',

      // MeMa
      mema_campaigns_cannot_be_invoices: 'MeMA kampanjer faktureras ej ifrån WOO',
    };

    return translations[error] !== undefined ? translations[error] : error;
  };

  goalFormatLabel = (format?: string): string => {
    const combinationLabel = 'Kombination';
    if (!format?.length) {
      return combinationLabel;
    }

    const translations = {
      longform: 'Longform',
      shortform: 'Shortform',
      not_set: combinationLabel,
    };

    return translations[format] !== undefined ? translations[format] : combinationLabel;
  };

  convertProgressStates = (progressState?: string): string => {
    if (!progressState?.length) {
      return '';
    }

    const translations = {
      generating_gambling_rows: 'Skapar spel-rader',
      generating_pause_ad_rows: 'Skapar pausreklam-rader',
      converting_video_rows_to_cv: 'Converterar video-rader til CV',
      generating_publisher_group_video_rows: 'Skapar Publicistgruppdelar för video-rader',
      generating_publisher_group_pause_ad_rows: 'Skapar Publicistgruppdelar för pausreklam-rader',
      validating_existing_video_inventory: 'Utvärderar skillnad mellan nytt och befintligt Videolager',
      validating_existing_pause_ad_inventory: 'Utvärderar skillnad mellan nytt och befintligt Pausreklamlager',
      saving_video_inventory_diff: 'Sparar lagerändringen för video',
      saving_pause_ad_inventory_diff: 'Sparar lagerändringen för pausreklam',
      loading_video_inventory_diff: 'Laddar lagerändringen för video',
      loading_pause_ad_inventory_diff: 'Laddar lagerändringen för pausreklam',
      update_program_period: 'Updaterar programperioder i WOO',
      updating_video_inventory: 'Updaterar Videolager',
      updating_pause_ad_inventory: 'Updaterar Pausreklamlager',
    };

    return translations[progressState] !== undefined ? translations[progressState] : progressState;
  };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { each, filter, find, map, sortBy } from 'lodash-es';
import { lastValueFrom, Subject } from 'rxjs';
import { jQueryLikeParamSerializer } from '../angularjs/http';
import { getNoCacheHeader } from '../utils/http';
import { compact } from '../utils/object';
import { EnvironmentService } from './EnvironmentService';
import { Job, JobService, ProgressCallback } from './JobService';
import { wooId } from './shared-types';
import { NamedBlob, TemporaryFileService } from './TemporaryFileService';
import { TranslationService } from './TranslationService';

@Injectable({ providedIn: 'root' })
export class InventoryService {
  filterOptions: InventoryFilterOption[];
  clear$: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private translationService: TranslationService,
    private jobService: JobService,
    private temporaryFileService: TemporaryFileService,
  ) {}

  getFilterOptions(): Promise<InventoryFilterOption[]> {
    return lastValueFrom(this.http.get<InventoryFilterOption[]>(`${this.env.apiUrl}/inventory/filter_options`));
  }

  getFilterOptionsForBuylist(): Promise<InventoryFilterOption[]> {
    return lastValueFrom(this.http.get<InventoryFilterOption[]>(`${this.env.apiUrl}/inventory/buylist_filter_options`));
  }

  filter(params: InventoryWeeks): Promise<any> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/inventory/filter?` + jQueryLikeParamSerializer(compact(params)), {
        headers: getNoCacheHeader(),
      }),
    );
  }

  buylistFilter(params: InventoryWeeks): Promise<any> {
    return lastValueFrom(
      this.http.get(`${this.env.apiUrl}/inventory/buylist_filter?` + jQueryLikeParamSerializer(compact(params)), {
        headers: getNoCacheHeader(),
      }),
    );
  }

  async export(params: Record<string, string>, progressCallback: ProgressCallback): Promise<NamedBlob> {
    const job = await lastValueFrom(
      this.http.get<Job>(`${this.env.apiUrl}/inventory/export`, {
        params: params,
      }),
    );
    return this.temporaryFileService.download(
      (await this.jobService.waitForJobCompletion(job.id, progressCallback)).temporary_file_id,
    );
  }

  getMonthlyInventoryReports(): Promise<InventoryReportFileData[]> {
    return lastValueFrom(this.http.get<InventoryReportFileData[]>(`${this.env.apiUrl}/inventory/files`));
  }

  downloadReport(id: wooId): Promise<Blob> {
    return lastValueFrom(
      this.http.get<Blob>(`${this.env.apiUrl}/inventory/file/${id}`, {
        responseType: 'blob' as 'json',
      }),
    );
  }

  buildFilterParams(
    filters: { [name: string]: InventoryFilterValue[] },
    filterParams: InventoryWeeks | Record<string, string>,
    filterOptions: InventoryFilterOption[],
  ): InventoryWeeks | Record<string, string | string[]> {
    each(filters, function (filterValues, filterKey) {
      filterParams[filterKey] = map(filterValues, 'key');
      const filterOption = find(filterOptions, { filter_key: filterKey });
      if (filterOption.accepted_values === 1) {
        filterParams[filterKey] = filterParams[filterKey][0];
      }
    });
    return filterParams;
  }

  buildWeekParams(weeks: InventoryWeeks): Record<string, string> {
    return {
      week_start: weeks.startWeekYear + ':' + weeks.startWeekNumber,
      week_end: weeks.endWeekYear + ':' + weeks.endWeekNumber,
    };
  }

  validateWeekSpan(weeks: InventoryWeeks, changedField: string): void {
    const counterparts = {
      startWeekYear: 'endWeekYear',
      endWeekYear: 'startWeekYear',
      startWeekNumber: 'endWeekNumber',
      endWeekNumber: 'startWeekNumber',
    };

    const counterpart = counterparts[changedField];

    if (
      weeks.endWeekYear < weeks.startWeekYear ||
      (weeks.endWeekNumber < weeks.startWeekNumber && weeks.endWeekYear === weeks.startWeekYear)
    ) {
      weeks[counterpart] = weeks[changedField];
    }
  }

  getBreadcrumbFromCurrentFilters(filters: { [name: string]: InventoryFilterValue[] }): string {
    const filterKeys = ['weeks', 'week'].concat(
      Object.keys(filters).filter(function (filterKey) {
        return filters[filterKey] !== undefined && filters[filterKey].length > 0;
      }),
    );
    return this.getBreadcrumb(filterKeys);
  }

  getBreadcrumb(filterKeys: string[]): string {
    const order = [
      'weeks',
      'week',
      'publisher_groups',
      'publishers',
      'product_formats',
      'slot_types',
      'regions',
      'categories',
      'programs',
      'advanced_target_groups',
      'genders',
      'ages',
      'behaviors',
      'device_groups',
    ];

    let filteredKeys = filter(filterKeys, (item) => order.includes(item));
    filteredKeys = sortBy(filteredKeys, (item) => order.indexOf(item));

    if (filteredKeys.length > 0) {
      filteredKeys[0] = 'Totalt';
    }

    return filteredKeys.map((key) => this.translationService.convertInventoryFilter(key)).join(' > ');
  }

  pruneZerosFromInventoryTree(treeOriginal: InventoryTree, propertiesOriginal: string[]): InventoryTree {
    if (propertiesOriginal.length === 0) {
      throw new Error('Properties must be a non empty list');
    }

    function _filter(tree: InventoryTree, properties: string[]): InventoryTree {
      const newTree = Object.assign({}, tree);
      newTree.children = [];
      tree.children.forEach(function (child) {
        const newChild = _filter(child, properties);
        if (newChild) {
          newTree.children.push(newChild);
        }
      });

      const allPropertiesAreZero = properties.every(function (property) {
        return tree[property] === 0;
      });

      const allChildrenArePruned = newTree.children.every(function (child) {
        return child === null;
      });

      if (allPropertiesAreZero && allChildrenArePruned) {
        return null;
      } else {
        return newTree;
      }
    }

    return _filter(treeOriginal, propertiesOriginal);
  }

  pushRadioOptionsToLast(options: InventoryFilterOption[]): InventoryFilterOption[] {
    options.forEach((option) => {
      if (option.accepted_values === 1 && !option.optional) {
        options.push(options.splice(options.indexOf(option), 1)[0]);
      }
    });

    return options;
  }
}

export interface InventoryFilterOption {
  filter_key: FilterOptionKeys | string;
  values: InventoryFilterValue[];
  optional: boolean;
  accepted_values?: number;
}

export interface InventoryFilterValue {
  key: string;
  name: string;
  id?: wooId;
}

export interface InventoryWeeks {
  startWeekYear: number;
  startWeekNumber: number;
  endWeekYear: number;
  endWeekNumber: number;
}

export interface InventoryReportFileData {
  id: wooId;
  description: string;
  created_at: string;
}

export interface CampaignInventoryTrees {
  video_inventory_tree: InventoryTree;
  online_video_inventory_tree: InventoryTree;
  pause_inventory_tree: InventoryTree;
}

export interface InventoryTree {
  filter_key: string;
  filter_value_key: string;
  estimated?: number;
  used?: number;
  ordered?: number;
  children: InventoryTree[];
}

export enum FilterOptionKeys {
  ProductFormat = 'product_format',
  FilmLength = 'film_length',
  Regions = 'regions',
  Categories = 'categories',
  Programs = 'programs',
  Genders = 'genders',
  Ages = 'ages',
  DeviceGroups = 'device_groups',
  Trades = 'trades',
  AdvancedTargetGroups = 'advanced_target_groups',
  Dayparts = 'dayparts',
  SlotTypes = 'slot_types',
  FrequencyLimit = 'frequency_limit',
  AutomatedGuaranteed = 'automated_guaranteed',
}

import { Component } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserRole } from '../../../utils/user-roles';
import { ListVouchersMode } from '../../../woo_components.module/discounts/vouchers/vouchers-list.component';
import {
  AdHocContract,
  AdHocContractParams,
  AdHocContractService,
} from '../../../woo_services.module/AdHocContractService';
import { AuthService } from '../../../woo_services.module/AuthService';
import { CustomerService } from '../../../woo_services.module/CustomerService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { VoucherParams, VoucherService } from '../../../woo_services.module/VoucherService';
import {
  AgencyType,
  Brand,
  Campaign,
  CompactAgency,
  CompactCustomer,
  Contract,
  Customer,
  Voucher,
  wooId,
} from '../../../woo_services.module/shared-types';
import { AdvancedCampaignService } from '../../services/AdvancedCampaignService';
import { CachedAdOrganisationService } from '../../services/CachedAdOrganisationService';
import { CachedCustomerService } from '../../services/CachedCustomerService';
import { BookingModel, BookingStore } from '../../stores/BookingStore';
import { BookingStoreSelectors } from '../../stores/BookingStoreSelectors';
import { SubscriptionHandler } from '../subscription-handler';
import { NewDiscountDialog } from './new-discount-dialog.component';

@Component({
  selector: 'campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetails extends SubscriptionHandler {
  readonly adminOrPlanner = this.authService.hasAnyRole([UserRole.admin, UserRole.planner]);
  readonly voucherListMode = this.adminOrPlanner ? ListVouchersMode.edit : ListVouchersMode.readOnly;
  readonly canDeleteAdhoc = this.adminOrPlanner;

  campaign: Campaign;
  agencyId: wooId;
  referenceNumber: string;
  clientInvoiceReference: string;
  title: string;
  inventoryOnlyCampaign = false;
  editingBooked: boolean;

  customerContracts: Contract[] = [];
  customerAdHocContracts: Contract[] = [];
  customerVouchers: Voucher[] = [];
  customerBrands: Brand[] = [];

  customers: CompactCustomer[] = [];
  availableAgencies: CompactAgency[] = [];
  selectedAgency: CompactAgency;
  selectedCustomer: CompactCustomer;
  selectedCustomerAgreementsInfo: CustomerAgreementsInfo = {};
  selectedBrand: Brand;
  initiating = true;
  showCustomerNickname: (args: any) => string;

  get selectedAgreementsInfo(): AgreementsInfo {
    return this.selectedCustomerAgreementsInfo[this.selectedAgency.id];
  }

  constructor(
    private cachedCustomerService: CachedCustomerService,
    private customerService: CustomerService,
    private bookingStore: BookingStore,
    private authService: AuthService,
    private selectors: BookingStoreSelectors,
    private voucherService: VoucherService,
    private adHocContractService: AdHocContractService,
    private campaignService: AdvancedCampaignService,
    private cachedAdOrganisationService: CachedAdOrganisationService,
    private dialogService: DialogService,
  ) {
    super();
    cachedCustomerService.getCustomersForCurrentUserExcludingLocked().then((customers) => {
      this.customers = customers;
      this.initFromStore(this.bookingStore.state);
      this.initiating = false;
    });
    this.addSubscription(
      this.bookingStore.state$
        .pipe(
          distinctUntilChanged(
            (prev: BookingModel, curr: BookingModel) => prev.campaign.customer_id === curr.campaign.customer_id,
          ),
        )
        .subscribe(this.initCustomerFromStore),
    );
    this.addSubscription(this.bookingStore.state$.subscribe(this.initFromStore));
    this.guessClientInvoiceReference();
    this.showCustomerNickname = this.customerDisplayName;
  }

  initCustomerFromStore = (model: BookingModel): void => {
    if (model.campaign.customer_id) {
      this.loadCustomerAndAgency(model.campaign.customer_id);
    }
  };

  initFromStore = (model: BookingModel): void => {
    this.campaign = model.campaign;
    this.inventoryOnlyCampaign = model.campaign.inventory_only_campaign;
    this.selectedCustomer = this.customers.find((customer) => customer.id === model.campaign.customer_id);
    this.agencyId = model.campaign.agency_id;
    this.referenceNumber = model.campaign.reference_number;
    this.clientInvoiceReference = model.campaign.client_invoice_reference;
    this.title = model.campaign.name;
    this.editingBooked = this.selectors.editingBooked(model.campaign);
    this.customerVouchers = model.selectedCustomer.vouchers.filter(
      (v) => !v.campaign || v.campaign.id === model.campaign.id,
    );
    this.showNewCustomerContractsOnBookedCampaign(this.editingBooked);
  };

  showNewCustomerContractsOnBookedCampaign = (bookedCampaign: boolean): void => {
    if (bookedCampaign) {
      this.customerContracts = this.campaign.use_new_contract ? this.campaign.new_contracts : this.campaign.contracts;
    }
  };

  agencyChanged = (): void => {
    const date = this.selectedAgency ? this.selectedAgreementsInfo.validFrom : null;
    this.bookingStore.setAgency(this.selectedAgency, date);
  };

  customerChanged = (): void => {
    this.bookingStore.setCustomer(this.selectedCustomer);
  };

  nameUpdated = (): void => {
    this.bookingStore.setName(this.title.trim());
  };

  brandChanged = (): void => {
    this.bookingStore.setBrand(this.selectedBrand);
  };

  referenceNumberUpdated = (): void => {
    this.bookingStore.setReferenceNumber(this.referenceNumber);
  };

  clientInvoiceReferenceUpdated = (): void => {
    this.bookingStore.setClientInvoiceReference(this.clientInvoiceReference);
  };

  toggleInventoryOnlyCampaign(): void {
    this.bookingStore.setInventoryOnlyCampaign(this.inventoryOnlyCampaign);
  }

  showContractDiscounts = (): boolean => {
    return (
      this.selectedCustomer &&
      ((this.selectedAgency && this.selectedAgreementsInfo && this.selectedAgreementsInfo.hasContractDiscounts) ||
        (this.adminOrPlanner && !this.selectedAgency))
    );
  };

  clientCustomer = (): boolean => {
    return this.authService.hasRole(UserRole.client);
  };

  createVoucher = (): void => {
    this.cachedAdOrganisationService.getAdOrganisations().then((adOrgs) => {
      this.dialogService
        .create(NewDiscountDialog)
        .showNewVoucher(this.availableAgencies, adOrgs)
        .then(
          (params: VoucherParams) => {
            const customerId = this.selectedCustomer.id;
            this.voucherService.createVoucher(customerId, params).then(() => {
              this.cachedCustomerService.clear(customerId);
              this.loadCustomerAndAgency(customerId);
            });
          },
          () => null,
        );
    });
  };

  createAdHocContract = (): void => {
    this.cachedAdOrganisationService.getAdOrganisations().then((adOrgs) => {
      this.dialogService
        .create(NewDiscountDialog)
        .showNewAdhocContract(this.availableAgencies, adOrgs)
        .then(
          (params: AdHocContractParams) => {
            const customerId = this.selectedCustomer.id;
            this.adHocContractService.createAdHocContract(customerId, params).then(async () => {
              this.cachedCustomerService.clear(customerId);
              this.loadCustomerAndAgency(customerId);

              if (this.selectors.isValid(this.bookingStore.state)) {
                // Trigger estimation of campaign to get new estimation from api so the new discount can be used
                await this.campaignService.estimate(this.bookingStore.state.campaign);
              }
            });
          },
          () => null,
        );
    });
  };

  deleteVoucher = (voucher: Voucher): void => {
    this.voucherService.deleteVoucher(voucher.id).then(() => {
      const customerId = this.selectedCustomer.id;
      this.cachedCustomerService.clear(customerId);
      this.loadCustomerAndAgency(customerId);
    });
  };

  deleteAdHoc = (contract: AdHocContract): void => {
    this.adHocContractService.deleteAdHocContract(contract.id).then(async () => {
      const customerId = this.selectedCustomer.id;
      this.cachedCustomerService.clear(customerId);
      this.loadCustomerAndAgency(customerId);

      if (this.selectors.isValid(this.bookingStore.state)) {
        // Trigger estimation of campaign to get new estimation from api so the new discount can be used
        await this.campaignService.estimate(this.bookingStore.state.campaign);
      }
    });
  };

  private customerDisplayName = (customer: Customer): string => {
    if (this.adminOrPlanner && !!customer.nickname?.length && customer.name !== customer.nickname)
      return `${customer.name} (${customer.nickname})`;

    return customer.name;
  };

  private guessClientInvoiceReference() {
    if (!this.adminOrPlanner && !this.clientInvoiceReference) {
      const user = this.authService.getUser();
      this.bookingStore.setClientInvoiceReference(`${user.first_name} ${user.last_name}`);
    }
  }

  private loadCustomerAndAgency(customerId: wooId) {
    this.cachedCustomerService.getCustomer(customerId).then((customer: Customer) => {
      const model = this.bookingStore.state;
      const editingBooked = this.selectors.editingBooked(model.campaign);

      if (editingBooked) {
        this.customerContracts = model.campaign.contracts;
        this.customerAdHocContracts = model.campaign.ad_hoc_contracts;
      } else {
        this.customerContracts = customer.contracts;
        this.customerAdHocContracts = customer.ad_hoc_contracts;
      }
      this.setAvailableAgencies(customer);
      this.setBrand(customer, model.campaign.brand);
      this.bookingStore.setCustomerData(customer);
      this.selectedAgency = this.availableAgencies.find((agency) => agency.id === model.campaign.agency_id);
      if (!this.selectedAgency && !editingBooked && !this.adminOrPlanner) {
        this.selectedAgency = this.availableAgencies[0];
        this.agencyChanged();
      }
    });
  }

  private setAvailableAgencies(customer: Customer) {
    const validAgreements = this.customerService.getValidAgreements(customer.partner_agreements);
    this.availableAgencies = validAgreements.map((agreement) => agreement.agency);
    validAgreements.forEach((agreement) => {
      const allowedAgencyTypes = [
        AgencyType.mediaAgency,
        AgencyType.digitalMediaAgencyWithDiscount,
        AgencyType.tradingAgency,
      ];
      this.selectedCustomerAgreementsInfo[agreement.agency.id] = {
        hasContractDiscounts: allowedAgencyTypes.includes(agreement.agency_type),
        validFrom: new Date(agreement.valid_from),
      };
    });
  }

  private setBrand(customer: Customer, campaignBrand: Brand | null) {
    this.customerBrands = customer.brands.sort((a, b) => a.name.localeCompare(b.name, 'sv'));

    if (campaignBrand) {
      this.selectedBrand = campaignBrand;
    } else if (customer.brands.length === 1) {
      this.selectedBrand = customer.brands[0];
    } else {
      this.selectedBrand = null;
    }

    this.brandChanged();
  }
}

interface CustomerAgreementsInfo {
  [key: string]: AgreementsInfo;
}
interface AgreementsInfo {
  hasContractDiscounts: boolean;
  validFrom: Date;
}

import { UserRole } from '../utils/user-roles';
import { SpecialCase } from './TargetingCombinationRulesService';

// --------- Types --------- //
export type CompactUser = Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'allowed_to_sign_in' | 'roles'>;
export type errorMessage = string;
export type ProgramSegment = Segment & { id: wooId };
export type SlotTypeEstimates = Partial<Record<SlotType, number>>;
export type UpdateGoalsJobTargetingData = Pick<
  Targeting,
  'id' | 'additional_goal_budget' | 'additional_goal_budget_message'
> & {
  goals: Pick<Goal, 'id' | 'additional_impressions'>[];
};
export type wooId = string;

// --------- Interfaces --------- //

export interface AdOrganisation {
  id: wooId;
  name: string;
  external: boolean;
}

export interface Agency {
  id: wooId;
  name: string;
  accountable: string;
  address_1: string;
  address_2: string;
  agency_discount: string;
  city: string;
  country: string;
  country_code: string;
  crm_id?: string;
  crm_last_modified_at: null;
  crm_last_synced_at: null;
  customers: CompactCustomer[];
  inactive_customers: CompactCustomer[];
  email: string;
  has_discount: boolean;
  hq_country: string;
  hq_country_code: string;
  invoice_customer: boolean;
  locked: boolean;
  low_credit_rating: boolean;
  nickname: string;
  org_no: string;
  partner_agreements: PartnerAgreement[];
  inactive_partner_agreements: PartnerAgreement[];
  payment_terms: number;
  payment_terms_code?: string;
  phone_no: string;
  self_service_compensation: string;
  tsc_id?: string;
  users: User[];
  woo_contact_email: string;
  zip_code: string;
}

export interface AgencySelfServiceDetails {
  compensation_enabled: boolean;
  comment?: string;
  compensation: number;
}

export interface AgeTargetGroup extends GenderTargetGroup {
  max_age: number;
  min_age: number;
}

export interface Agreement {
  total_net_views: number;
  total_net_budget: number;
  gross_budget: number;
  additional_gross_budget: number;
  net_budget: number;
  additional_net_budget: number;
  net_net_budget: number;
  gross_views: number;
  additional_gross_views: number;
  average_net_cpm: string;
  average_gross_cpm: string;
  average_discount: string;
  net_views: number;
  view_currency: ViewCurrency;
  additional_net_views: number;
  total_inventory_views: number;
  parts: AgreementPart[];
  gross_rating_views_summary: ViewCurrencySummary;
  completed_views_summary: ViewCurrencySummary;
  impressions_summary: ViewCurrencySummary;
  pause_ad_summary: ViewCurrencySummary;
  online_video_summary: ViewCurrencySummary;
}

export interface AgreementPart {
  share_type: 'standard';
  inventory_type: string;
  discount: string;
  gross_cpm: string;
  net_cpm: string;
  gross_cost_per_inventory_view: number;
  net_cost_per_inventory_view: number;
  total_net_views: number;
  total_net_budget: number;
  total_gross_views: number;
  total_gross_budget: number;
  gross_budget: number;
  additional_gross_budget: number;
  net_budget: number;
  additional_net_budget: number;
  gross_views: number;
  additional_gross_views: number;
  net_views: number;
  additional_net_views: number;
  total_inventory_views: number;
  creatives?: AgreementPartCreative[];
}

export interface AgreementPartCreative {
  net_views: number;
  additional_net_views: number;
  id: wooId;
}

export interface Brand {
  id: wooId;
  mrm_id: string;
  name: string;
}

export interface Campaign {
  id: wooId;
  ttv_id: string | null;
  name: string;
  booked_date: string | Date;
  reference_number: string;
  status: CampaignStatus;
  status_comment?: string;
  closed_message?: string;
  updated_at: string | Date;
  created_at: string | Date;
  view_currency: ViewCurrency | CombinedViewCurrencies;
  customer_name: string;
  customer_id: wooId;
  external_id?: string;
  enabled: boolean;
  impressions_to_deliver: number;
  additional_goal_impressions: number;
  adjusted_goal_impressions: number;
  adjusted_goal_order_views: number;
  mms_gain: number;
  mms_gain_impressions: number;
  mms_gain_order_views: number;
  automatic_mms_gain: boolean;
  mms_tracked?: boolean;
  sent_to_ad_server_at?: string | Date;
  additional_info: string;
  booked_by: string;
  reject_message?: string;
  client_invoice_reference: string;
  tracking?: Tracking;
  agresso_invoice_token: string;
  outcome_up_to_date: boolean;
  agency_id?: wooId;
  agency_name?: string;
  agency_nickname?: string;
  contracts: any[];
  temporary_discounts: any[];
  has_sent_invoices: boolean;
  low_credit_rating: boolean;
  ad_organisation_id: wooId;
  ad_organisation_name: string;
  ad_organisation_external: boolean;
  ad_server_status: AdServerStatus;
  outcome_report_id?: wooId;
  voucher_ids: wooId[];
  completion_rates_at_booking?: any;
  ad_hoc_contracts: any[];
  targetings: Targeting[];
  agreement?: Agreement;
  agency_self_service_details: AgencySelfServiceDetails;
  cash_campaign: boolean;
  gambling_campaign: boolean;
  political_campaign: boolean;
  inventory_only_campaign: boolean;
  ttv_campaign: boolean;
  mema_campaign: boolean;
  editable: boolean;
  order_value: number;
  new_order_value?: number;
  default_video_frequency_limit: boolean;
  new_contracts: any[];
  use_new_contract: boolean;
  duplicated_id?: wooId;
  ad_server?: string;
  brand?: Brand;
}

export interface CampaignBookingJobResult {
  campaign_id: string;
  booking_error: string;
  campaign_status: string;
  overbooked_impressions: number;
}

export interface CampaignEstimation {
  status: 'ok' | 'error' | string;
  net_budget: number;
  additional_net_budget: number;
  gross_budget: number;
  additional_gross_budget: number;
  gross_impressions: number;
  additional_gross_impressions: number;
  net_impressions: number;
  additional_net_impressions: number;
  total_net_impressions: number;
  total_gross_impressions: number;
  creatives: Creative[];
  inventory_paths: any[];
  average_net_cpm: number;
  average_gross_cpm: number;
  average_discount: number;
  view_currency: ViewCurrency;
  parts: CampaignEstimationPart[];
}

export interface CampaignEstimationPart {
  net_budget: number;
  gross_budget: number;
  additional_net_budget: number;
  additional_gross_budget: number;
  gross_cpm: string;
  net_cpm: string;
  gross_cost_per_inventory_view: number;
  net_cost_per_inventory_view: number;
  gross_views: number;
  net_views: number;
  additional_gross_views: number;
  additional_net_views: number;
  total_net_budget: number;
  total_gross_budget: number;
  total_net_views: number;
  total_gross_views: number;
  total_inventory_views: number;
  discount: string;
  creatives: CampaignEstimationPartCreative[];
  targeting_id: wooId;
  overbooked_impressions: number;
  unbookable_weeks: CommercialWeek[];
  forced_new_start_date?: Date;
  forced_new_end_date?: Date;
  full_targeting_type?: FullTargetingType;
}

export interface CampaignEstimationPartCreative {
  creative_id: wooId;
  creative_quota: number;
  gross_budget: number;
  net_budget: number;
  gross_cpm: number;
  net_cpm: number;
  gross_views: number;
  net_views: number;
  additional_gross_views: number;
  additional_net_views: number;
  additional_gross_budget: number;
  additional_net_budget: number;
  discount: number;
}

export interface Category {
  id: string;
  name: string;
  is_disney: boolean;
  product_format_category_share?: CategoryShares[];
}

export interface CategoryShares {
  id?: string;
  preroll: number;
  midroll?: number;
  postroll?: number;
  product_format_id: string;
  program_format_id?: string;
}

export interface CommercialWeek {
  year: number;
  week: number;
}

export interface CompactAgency {
  id: wooId;
  name: string;
  crm_id: string;
  low_credit_rating: boolean;
  invoice_customer: boolean;
  has_discount: boolean;
  agency_discount: number;
  nickname?: string;
  locked: boolean;
}

export interface CompactCampaign {
  id: string;
  name: string;
  budget: number;
  status: CampaignStatus;
  view_currency: ViewCurrency | CombinedViewCurrencies;
  status_comment?: string;
  closed_message?: string;
  customer_name: string;
  customer_id: string;
  budgeted_impressions: number;
  external_id?: string;
  ad_server_status: 'unsent' | 'dirty' | 'sent';
  impressions_to_deliver: number;
  additional_goal_impressions: number;
  adjusted_goal_impressions: number;
  mms_gain_impressions: number;
  adjusted_goal_order_views: number;
  mms_gain_order_views: number;
  tracking?: Tracking;
  has_sent_invoices: boolean;
  cash_campaign: boolean;
  gambling_campaign: boolean;
  political_campaign: boolean;
  ttv_campaign: boolean;
  mema_campaign: boolean;
  start_date: string;
  end_date: string;
  creatives: Creative[];
  ad_server?: string;
}

export interface CompactCustomer {
  id: wooId;
  name: string;
  crm_id?: string;
  nickname?: string;
  locked: boolean;
  active_customer: boolean;
}

export interface Creative {
  id: wooId;
  creative_type: VideoCreativeType | ImageCreativeType | '';
  video_code?: string;
  asset_url: string;
  length?: number;
  destination_url: string;
  click_tracking_url: string;
  impression_tracking_urls: string[];
  custom_name: string;
  video_title?: string;
  custom_text: string;
  quota: number;
  has_image?: boolean;
  segments: Segment[];
  budget: number;
  budget_decimals: number;
  status: CreativeStatus;
}

export interface CreativeAsset {
  id: wooId;
  asset_type: CreativeAssetType;
  created_at: string;
  duration: number;
  etag: string;
  file_size: number;
  name: string;
  public_url: string;
  video_code: string;
}

export interface CodeErrors {
  prefix?: errorMessage;
  typeCode?: errorMessage;
  codeLength?: errorMessage;
  filmLength?: errorMessage;
  whiteSpace?: errorMessage;
  version?: errorMessage;
}

export interface CodeService {
  validate(code: string): CodeErrors;
  getFilmLength(code: string): number | null;
}

export interface Contract {
  id: string;
  name?: string;
  start_date: string;
  end_date: string;
  owner_id: string;
  owner_name: string;
  discounts: ContractDiscount[];
  crm_last_modified_at?: string;
  crm_last_synced_at?: string;
  valid_for_interim: boolean;
  ended: boolean;
}

export interface ContractDiscount {
  id: string;
  discount_types: string[];
  discount_rate: string; // Bignum formatted as string
  discount_contract_type: DiscountContractType;
  end_date: string | Date;
  start_date: string | Date;
  yclis?: ContractDiscount[];
}

export interface Customer {
  id: string;
  name: string;
  nickname?: string;
  mrm_id: string;
  low_credit_rating: boolean;
  org_no: string;
  hq_country_code: string;
  email?: string;
  phone_no?: string;
  address_1: string;
  address_2?: string;
  zip_code: string;
  city: string;
  country_code: string;
  accountable: string;
  locked: boolean;
  crm_id?: string;
  hq_country: string;
  country: string;
  temporary_discounts: any[];
  payment_terms: number;
  payment_terms_code?: string;
  crm_last_modified_at?: string;
  crm_last_synced_at?: string;
  cash_customer: boolean;
  users: User[];
  previous_agency_users: User[];
  agencies: CompactAgency[];
  previous_agencies: CompactAgency[];
  partner_agreements: PartnerAgreement[];
  inactive_partner_agreements: PartnerAgreement[];
  contracts: Contract[];
  tsc_id?: string;
  vouchers: Voucher[];
  ad_hoc_contracts: any[];
  gambling_customer: boolean;
  political_customer: boolean;
  brands: Brand[];
}

export interface DatePeriod {
  start: Date;
  end: Date;
}

export interface DateSegment {
  start_date: Date;
  end_date: Date;
}

export interface Daypart {
  id: wooId;
  name: DaypartName;
  description: string;
}
export interface DefaultFrequencyLimit {
  id: wooId;
  impressions: number;
  name: string;
  active: boolean;
  editable: boolean;
  time_unit: string;
  default_for: string;
}

export interface DefaultTargetingData {
  current_gross_rating_factor: number;
  full_universe: number;
}

export interface DeviceGroup {
  id: wooId;
  name: string;
  active: boolean;
  description: string;
  outstream_shares: OutstreamEstimates;
  outstream_withdrawal_limits: OutstreamEstimates;
  shares: DeviceGroupPublisherShares;
  withdrawal_limits: DeviceGroupPublisherShares;
  addon_targetable: boolean;
  disabled?: boolean;
}

export interface DeviceGroupPublisherShares {
  [publisherId: string]: PerIdEstimates;
}

export interface EstimateObject {
  id: string;
  name?: string;
  slot_types: SlotType[];
}

export interface ExtendCampaignBudgetJobResult {
  campaign_id: string;
  booking_error: string;
  over_booked_video?: number;
  over_booked_pause?: number;
  campaign_status: string;
  overbooked_impressions: number;
}

export interface ExtendedTargetingBudget {
  budget: number;
  impressions: number;
  message: string;
  additional_budget: number;
  additional_impressions: number;
  additional_message: string;
  date: Date;
  extended_by: string;
}

export interface FrequencyLimit {
  id: wooId;
  name: string;
  time_unit: 'campaign' | 'week';
  rbs_default: boolean;
  impressions: number;
  product_formats: ProductFormat[];
  active: boolean;
}

export interface GenderTargetGroup {
  active: boolean;
  id: wooId;
  legacy_tag: string;
  name: string;
  tag: string;
}

export interface Goal {
  id: wooId;
  name: string;
  start_date: string | Date;
  end_date: string | Date;
  view_currency: ViewCurrency;
  impressions: number;
  order_views: number;
  additional_impressions: number;
  additional_order_views: number;
  additional_budget_impressions: number;
  mms_gain_impressions: number;
  mms_gain_order_views: number;
  extended_budget_impressions: number;
  extended_additional_budget_impressions: number;
  format?: string;
  allow_mms_gain: boolean;
  ended: boolean;
  ad_server_view_currency: ViewCurrency;
}

export interface GrossRatingFactor {
  gross_rating_factor: number;
}

export interface HistoryItem {
  model: string;
  action: HistoryItemAction;
  updated_id: string;
  updated_name: string;
  modified: { [key: string]: string };
  original: { [key: string]: string };
  updated_at: string;
}

export interface NameIdTuple {
  name: string;
  id: string;
}

export interface OutstreamEstimates {
  [publisherId: string]: number;
}

export interface PartnerAgreement {
  agency: CompactAgency;
  agency_type: AgencyType;
  crm_id: string;
  crm_last_modified_at: string;
  crm_last_synced_at: string;
  customer: CompactCustomer;
  id: wooId;
  valid_from: string;
  valid_to: string;
}

export interface PerIdEstimates {
  [key: string]: SlotTypeEstimates;
}

export interface Period {
  start?: Date | string;
  end?: Date | string;
}

export interface ProductFormat {
  id: wooId;
  name: string;
  publisher_id: wooId;
  enabled: boolean;
  derived_slot_type_shares: any; // TODO: Should not be any
  regional_shares: Record<wooId, number>;
  device_group_shares: PerIdEstimates;
  advanced_target_group_shares: PerIdEstimates;
  behavior_shares: PerIdEstimates;
  daypart_withdrawal_limits: PerIdEstimates;
  device_group_withdrawal_limits: PerIdEstimates;
  advanced_target_group_withdrawal_limits: PerIdEstimates;
  behavior_withdrawal_limits: PerIdEstimates;
  category_withdrawal_limits: PerIdEstimates;
  gambling_withdrawal_limits: SlotTypeEstimates;
  gambling_pause_ad_share: number;
  gambling_withdrawal_limit_shares: SlotTypeEstimates;
  gambling_pause_ad_withdrawal_limit: number;
  pause_ad_share: number;
  pause_ad_withdrawal_limit: number;
  withdrawal_limits: SlotTypeEstimates;
  ag_withdrawal_limits: SlotTypeEstimates;
  spot_length_limits: SlotTypeEstimates;
  target_group_shares: TargetGroupShares[];
  target_group_shares_week_index: Record<number, TargetGroupShares>;
  target_group_withdrawal_limits: TargetGroupShares[];
  frequency_limit_ratios: SlotTypeEstimates;
  product_format_shares: SlotTypeEstimates;
  slot_types: SlotType[];
  format: ProductFormats;
}

export interface ProductFormatConditions {
  onlyShortFormat: boolean;
}

export interface Program {
  id: wooId;
  name: string;
  mrm_id: string;
  active: boolean;
  is_site_group: boolean;
  publisher_id: wooId;
  publisher_name: string;
  category_id: wooId;
  category_name: string;
  periods: ProgramSegment[];
  product_format_program_share: ProgramShares[];
}

export interface ProgramShares {
  id?: string;
  preroll: number;
  midroll?: number;
  postroll?: number;
  product_format_id: string;
  program_format_id?: string;
}

export interface Publisher {
  id: string;
  name: string;
  enabled: boolean;
  slot_types: SlotType[];
  pause_ad_share?: number;
  pause_ad_withdrawal_limit?: number;
  categories?: Category[];
  withdrawal_limits?: SlotTypeEstimates;
  category_withdrawal_limits?: { [key: string]: SlotTypeEstimates };
  daypart_withdrawal_limits?: { [key: string]: SlotTypeEstimates };
  ag_withdrawal_limits?: SlotTypeEstimates;
  derived_slot_type_shares?: SlotTypeEstimates;
  gambling_withdrawal_limits?: SlotTypeEstimates;
  gambling_withdrawal_limit_shares?: SlotTypeEstimates;
  gambling_pause_ad_share?: number;
  gambling_pause_ad_withdrawal_limit?: number;
  completion_rates?: SlotTypeEstimates;
  product_formats?: ProductFormat[];
}

export interface Region {
  id: wooId;
  name: string;
  enabled: boolean;
  shares: { [publisherId: string]: number };
  region_group?: RegionGroup;
}

export interface RegionGroup {
  id: wooId;
  name: string;
}

export interface RuleSelection {
  targetingType: TargetingType;
  geoTargeting: GeoTargeting;
  productFormat: ProductFormats;
  productChoice: ProductChoice;
  addonTargeting?: ProductChoice;
  specialCase?: SpecialCase;
}

export interface Segment {
  start_date: string | Date;
  end_date: string | Date;
}

export interface TargetGroup {
  genders: GenderTargetGroup[];
  ages: AgeTargetGroup[];
}

export interface TargetGroupShares {
  shares: SlotTypeEstimates;
  ages: PerIdEstimates;
}

export interface Targeting {
  id: wooId;
  start_date: Date | string;
  end_date: Date | string;
  age_target_groups: TargetingAgeTargetGroup[];
  categories: TargetingCategory[];
  dayparts: TargetingDaypart[];
  device_groups: TargetingDeviceGroup[];
  frequency_limit?: FrequencyLimit;
  gender_target_groups: TargetingGenderTargetGroup[];
  advanced_target_groups: TargetingAdvancedTargetGroup[];
  goals: Goal[];
  program_formats: TargetingProgram[];
  regions: TargetingRegion[];
  publishers: TargetingPublisher[];
  behaviors: any[];
  publisher_groups: TargetingPublisherGroup[];
  regional_share_type: ShareType;
  program_format_share_type: ShareType;
  publisher_share_type: 'woo' | 'budget' | 'impressions';
  publisher_group_share_type: 'woo' | 'percent';
  creative_share_type: CreativeShareType;
  creatives: Creative[];
  view_currency: ViewCurrency;
  regional_division: RegionalDivision;
  budget: number;
  budgeted_impressions: number;
  additional_budget: number;
  additional_budgeted_impressions: number;
  additional_budget_message: string;
  calculate_from_budget: boolean;
  additional_goal_budget?: number;
  additional_goal_budget_message?: string;
  extended_budgets?: ExtendedTargetingBudget[];
  send_invoices: boolean;
  sales_order_number: string;
  invoice_disable_message: string;
  includes_linear: boolean;
  universe?: number;
  pause_ad?: boolean;
  full_universe?: number;
  product_formats: ProductFormats[];
  gross_rating_factor?: number;
  current_gross_rating_factor?: number;
}

export interface TargetingAdditionalGoalBudgetData {
  additionalGoalBudget: number;
  additionalGoalBudgetMessage: string;
}

export interface TargetingAdvancedTargetGroup {
  advanced_target_group_id: wooId;
  name: string;
}

export interface TargetingAgeTargetGroup {
  age_target_group_id: string;
  name: string;
  min_age: number;
  max_age: number;
}

export interface TargetingDaypart {
  daypart_id: wooId;
  name: DaypartName;
  description: string;
}

export interface TargetingDeviceGroup {
  device_group_id: wooId;
  name: string;
  addon_targetable: boolean;
}

export interface TargetingCategory {
  category_id: wooId;
  name: string;
  is_disney: boolean;
}

export interface TargetingGenderTargetGroup {
  gender_target_group_id: string;
  name: string;
}

export interface TargetingMetaData {
  geoTargeting: GeoTargeting;
  productChoice: ProductChoice;
  targetingType: TargetingType;
  productFormat: ProductFormats;
  addonTargeting: ProductChoice;
}

export interface TargetingProgram {
  program_format_id: wooId;
  name: string;
  budget: number;
  impressions: number;
  publisher_name: string;
  category: {
    id: wooId;
    name: string;
  };
}

export interface TargetingPublisher {
  publisher_id: string;
  name: string;
  quota?: number;
}

export interface TargetingPublisherGroup {
  publisher_group_id: string;
  name: string;
  quota?: number;
}

export interface TargetingRegion {
  region_id: wooId;
  name: string;
  budget?: number;
  impressions?: number;
}

export interface TargetingTargetGroup {
  ages: TargetingAgeTargetGroup[];
  genders: TargetingGenderTargetGroup[];
}

export interface SendToAdServerJobResult {
  status: string;
  error?: string;
  data_errors?: { [key: string]: string[] };
}

export interface Tracking {
  impressions: number;
  completed_views: number;
  gross_rating_views: number;
  progress: number;
}

export interface UpdateGoalsJobParams {
  campaign_id: Campaign['id'];
  mms_gain: Campaign['mms_gain'];
  force?: boolean;
  targetings: UpdateGoalsJobTargetingData[];
}

export interface UpdateGoalsJobResult {
  campaign_id: wooId;
  booking_error: GoalUpdateBookingError;
}

export interface ViewCurrencySummary {
  active: boolean;
  additional_net_views: number;
  net_views: number;
  view_currency: string;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  agency_ids: string[];
  accepted_terms_and_conditions: boolean;
  ad_organisation?: {
    name: string;
    id: wooId;
  };
  allowed_to_sign_in: boolean;
  reset_password_token?: string;
  roles: Array<{ name: UserRole; resource_id?: wooId }>;
  agency_admin?: boolean;
}

export interface Voucher {
  id: wooId;
  created_at: string;
  updated_at: string;
  discount: number;
  conditions: VoucherCondition[];
  agency?: CompactAgency;
  customer: CompactCustomer;
  ad_organisation: AdOrganisation;
  campaign: CompactCampaign;
}

export interface VoucherCondition {
  id: wooId;
  values: any[];
  type: VoucherConditionType;
}

// --------- Enums --------- //

export enum AdServerType {
  pulse = 'pulse',
  mrm = 'mrm',
  undetermined = 'undetermined',
}

export enum AdServerStatus {
  unsent = 'unsent',
  sent = 'sent',
  dirty = 'dirty',
}

export const enum AgencyType {
  mediaAgency = 'media_agency',
  digitalMediaAgencyWithDiscount = 'digital_media_agency_with_discount',
  advertiser = 'advertiser',
  advertisingAgency = 'advertising_agency',
  productionAgency = 'production_agency',
  digitalFirm = 'digital_firm',
  eventFirm = 'event_firm',
  digitalMediaAgency = 'digital_media_agency',
  tradingAgency = 'trading_agency',
}

export enum CampaignStatus {
  unbooked = 'unbooked',
  rejected = 'rejected',
  booked = 'booked',
  upcoming = 'upcoming',
  reviewing = 'waiting_for_review',
  ongoing = 'ongoing',
  ended = 'ended',
  closed = 'closed',
  cancelled = 'cancelled',
  stopped = 'stopped',
  approved = 'approved',
}

export enum CreativeAssetType {
  Video = 'video',
  Image = 'image',
}

export enum CreativeShareType {
  Percent = 'percent',
  Budget = 'budget',
}

export enum CreativeStatus {
  Unknown = 'unknown',
  NotReady = 'not_ready',
  PeachFileNotFound = 'peach_file_not_found',
  Ready = 'ready',
}

export enum DashboardLoad {
  campaign = 'campaign',
  campaigns = 'campaigns',
  organisations = 'organisations',
  search = 'search',
}

export enum DashboardTab {
  stats = 'stats',
  details = 'details',
  creatives = 'creatives',
  inventory = 'inventory',
  invoices = 'invoices',
  goals = 'goals',
  history = 'history',
  admin = 'admin',
  ttv = 'ttv',
}

export enum DaypartName {
  night = 'Natt',
  morning = 'Morgon',
  day = 'Dag',
  afternoon = 'Eftermiddag',
  evening = 'Kväll',
}

export enum DeviceGroupName {
  CTV = 'CTV',
  desktop = 'Desktop',
  mobile_and_tablet = 'Mobil & tablet',
}

export enum DiscountContractType {
  ycli = 'ycli',
  ycp = 'ycp',
}

export const enum FrequencyLimitTimeUnit {
  campaign = 'campaign',
  week = 'week',
}

export const enum FullTargetingType {
  rbs = 'RBS',
  specific = 'Specific',
}

export enum GeoTargeting {
  national = 'national',
  regional = 'regional',
}

export enum GoalUpdateBookingError {
  insufficientInventory = 'insufficient_inventory',
  goalTotalImpressionsBelowZero = 'goal_total_impressions_below_zero',
  goalUpdateSomeFailed = 'goal_update_some_failed',
  goalUpdateAllFailed = 'goal_update_all_failed',
  unspecifiedError = 'error',
}

export const enum HistoryItemAction {
  create = 'create',
  update = 'update',
  destroy = 'destroy',
}

export enum ImageCreativeType {
  pauseImage = 'pause_image',
}

export enum RegionalDivision {
  ZipCodeBased = 'zip_code_based',
}

export enum TargetingType {
  instream = 'instream',
  pause = 'pause',
}

export enum PriceListPriceGroups {
  publisher_base_prices = 'publisher_base_prices',
  publisher_targeting_prices = 'publisher_targeting_prices',
  publisher_age_modifier_prices = 'publisher_age_modifier_prices',
  publisher_locality_prices = 'publisher_locality_prices',
  publisher_addon_targeting_prices = 'publisher_addon_targeting_prices',
}

export enum PriceListBasePriceTypes {
  longform = 'longform',
  shortform = 'shortform',
  combination = 'combination',
  pause_longform = 'pause_longform',
  pause_shortform = 'pause_shortform',
  pause_combination = 'pause_combination',
  includes_linear = 'includes_linear',
}

export enum PriceListTargetingPriceTypes {
  rbs = 'rbs',
  category = 'category',
  program_format = 'program_format',
  daypart = 'daypart',
  device_group = 'device_group',
  advanced_target_group = 'advanced_target_group',
  gender = 'gender',
  age = 'age',
  gender_and_age = 'gender_and_age',
}

export enum ProductChoice {
  advancedTargetGroup = 'advancedTargetGroup',
  category = 'category',
  daypart = 'daypart',
  device = 'device',
  program = 'program',
  rbs = 'rbs',
  targetGroup = 'targetGroup',
  noChoice = 'noChoice',
}

export enum ProductFormats {
  combination = 'combination',
  longForm = 'longform',
  shortForm = 'shortform',
}

export enum ProgramCategoryName {
  documentary = 'Dokumentärer',
  food_home_leisure = 'Mat, Hem & Fritid',
  news_and_current_events = 'Nyheter & Aktualitet',
  entertainment = 'Nöje',
  sports = 'Sport',
  tv_shows = 'TV-serier',
  disney = 'Disney+',
  tv_channels = 'TV-kanaler',
}

export enum ShareType {
  WOO = 'woo',
  Budget = 'budget',
  Impressions = 'impressions',
  CompletedViews = Impressions, // This is just an alias for Impressions for now.
}

export const enum SlotType {
  preroll = 'preroll',
  postroll = 'postroll',
  midroll = 'midroll',
  pauseAd = 'pause_ad',
  prerollSticky = 'preroll_sticky',
  postrollSticky = 'postroll_sticky',
}

export const enum ValidationStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  Warning = 'warning',
  Unknown = 'unknown',
}

export enum ViewCurrency {
  impressions = 'impressions',
  completedViews = 'completed_views',
  grossRatingViews = 'gross_rating_views',
}

export enum CombinedViewCurrencies {
  impcv = 'impressions/completed_views',
  impgrv = 'impressions/gross_rating_views',
}

export enum VideoCreativeType {
  peach = 'peach',
  adstream = 'adstream',
  ag = 'automated_guaranteed',
  other = 'other',
}

export const enum VoucherConditionType {
  product = 'product',
}
